/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { shortenNumber, getTranslationFromKeys } from '../../common/utilities'

import { setFilters, setExpandedRows, setSelectedInstruments } from '../../actions/workspace'
import { I18nContext } from '../../containers/i18n'
import {
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  subscribeTradeSymbol,
  unsubscribeTradeSymbol,
} from '../../actions/marketData'
import Price from '../Price'
import { ExternalTable, InternalTable, Expander } from '../NativeTable'

function PriceList({
  //Own
  openFilter = false,
  expandedRows = [],
  selectedInstruments = {},
  filters: { selected = false },
  //Props
  tabIndex,
  //State
  instruments,
  instrumentsGroups,
  tick,
  trade,
  //Dispatch
  subscribeTickSymbol,
  subscribeTradeSymbol,
  unsubscribeTickSymbol,
  unsubscribeTradeSymbol,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}) {
  const { t } = useContext(I18nContext)
  const { colorMode, theme } = useThemeUI()

  // Build the default state of component with the actual instrument groups only if expandedRows not contains data
  useEffect(() => {
    if (JSON.stringify(expandedRows) === '{}') {
      const instrumentGroupKeys = Object.keys(instrumentsGroups)
      const initialExpandedRows = instrumentGroupKeys.reduce(
        (acc, value) => ({ ...acc, [value]: false }),
        {}
      )
      const initialSelectedInstruments = instrumentGroupKeys.reduce(
        (acc1, instrumentGroupKey) => ({
          ...acc1,
          [instrumentGroupKey]: instrumentsGroups[instrumentGroupKey].data.reduce(
            (acc2, instrumentKey) => ({ ...acc2, [instrumentKey]: false }),
            {}
          ),
        }),
        {}
      )
      setExpandedRows({ tabIndex: tabIndex, expandedRows: initialExpandedRows })
      setSelectedInstruments({
        tabIndex: tabIndex,
        selectedInstruments: initialSelectedInstruments,
      })
    }
  }, [])

  // Subscriptions

  // Handle subscription on mount
  useEffect(() => {
    const entries = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      entries.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId, true))
    } else {
      entries.forEach(instrumentTypeId => subscribeOnExpanded(instrumentTypeId))
    }
  }, [])

  function instrumentSelected(instrument, instrumentTypeId) {
    function isSelected() {
      return (
        selectedInstruments[instrumentTypeId] &&
        selectedInstruments[instrumentTypeId][`${instrument.exchangeId}:${instrument.symbol}`]
      )
    }
    return selected ? isSelected() : true
  }

  function subscribeOnExpanded(instrumentTypeId = '') {
    instrumentsGroups &&
      instrumentsGroups[instrumentTypeId] &&
      instrumentsGroups[instrumentTypeId].data
        .filter(instrumentId => instrumentSelected(instruments[instrumentId], instrumentTypeId))
        .forEach(instrumentId => {
          subscribe(instrumentId)
        })
  }

  function subscribe(instrumentId) {
    subscribeTradeSymbol({
      symbol: instruments[instrumentId].symbol,
      exchangeId: instruments[instrumentId].exchangeId,
    })
    subscribeTickSymbol({
      symbol: instruments[instrumentId].symbol,
      exchangeId: instruments[instrumentId].exchangeId,
    })
  }

  function unsubscribe(symbol) {
    unsubscribeTradeSymbol(symbol)
    unsubscribeTickSymbol(symbol)
  }

  function unsubscribeOnMinimize(instrumentTypeId = '') {
    instrumentsGroups &&
      instrumentsGroups[instrumentTypeId] &&
      instrumentsGroups[instrumentTypeId].data
        .filter(instrumentId => instrumentSelected(instruments[instrumentId], instrumentTypeId))
        .forEach(instrumentId => {
          unsubscribe(instruments[instrumentId].symbol)
        })
  }

  function subscribeOnUncheck(instrumentTypeId = '', onlyChecked = false) {
    instrumentsGroups[instrumentTypeId].data
      .filter(instrumentId => {
        if (selectedInstruments[instrumentTypeId]) {
          if (onlyChecked) {
            return selectedInstruments[instrumentTypeId][instrumentId]
          }
          return !selectedInstruments[instrumentTypeId][instrumentId]
        }
        return false
      })
      .forEach(instrumentId => {
        subscribe(instrumentId)
      })
  }

  function unsubscribeOnCheck(instrumentTypeId = '') {
    instrumentsGroups[instrumentTypeId].data
      .filter(
        instrumentId =>
          selectedInstruments[instrumentTypeId] &&
          !selectedInstruments[instrumentTypeId][instrumentId]
      )
      .forEach(instrument => {
        unsubscribe(instruments[instrument].symbol)
      })
  }

  // Expanded instruments by marketId, securityType and securitySubType
  const updateExpanded = instrumentTypeId => {
    const newExpanded = { ...expandedRows }
    newExpanded[instrumentTypeId] = !newExpanded[instrumentTypeId]
    setExpandedRows({ tabIndex: tabIndex, expandedRows: newExpanded })
    // Handle subscriptions on expanded elements
    if (newExpanded[instrumentTypeId]) {
      subscribeOnExpanded(instrumentTypeId)
    } else {
      unsubscribeOnMinimize(instrumentTypeId)
    }
  }

  // Selected instruments by marketId, securityType and securitySubType
  const updateSelected = (instrumentTypeId, exchangeId, symbol, checked) => {
    const newSelected = { ...selectedInstruments }

    newSelected[instrumentTypeId] = {
      ...newSelected[instrumentTypeId],
      [`${exchangeId}:${symbol}`]: checked,
    }

    setSelectedInstruments({ tabIndex: tabIndex, selectedInstruments: newSelected })
    // Handle unsubscription when selected filter is on and instrument is unseleted
    if (selected && !checked) {
      unsubscribe(symbol)
    }
  }

  function updateFilters(checked) {
    setFilters({ tabIndex: tabIndex, filters: { selected: checked } })
    // Handle subscriptions on filter change
    const instrumentTypeIdList = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (checked) {
      instrumentTypeIdList.forEach(instrumentTypeId => unsubscribeOnCheck(instrumentTypeId))
    } else {
      instrumentTypeIdList.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId))
    }
  }

  // Build array of groups
  const instrumentTypesList = useMemo(() => Object.keys(instrumentsGroups), [instruments])
  // Build array with the translation text for each group
  const instrumentTypesNameList = useMemo(
    () => instrumentTypesList.map(i => getTranslationFromKeys(i.split(':'), t)),
    [instruments]
  )

  function getSubComponentData(instrumentType) {
    const safePrice = (currency, key, decimals) =>
      currency?.[key] ? Number(currency[key]).toFixed(decimals) : ''
    const askPriceFixed = (tick, instrument) =>
      safePrice(tick, 'askPrice', instrument.priceDecimals)
    const bidPriceFixed = (tick, instrument) =>
      safePrice(tick, 'bidPrice', instrument.priceDecimals)

    const build = i => {
      if (
        selected &&
        selectedInstruments[instrumentType] &&
        !selectedInstruments[instrumentType][i]
      ) {
        return null
      }
      const currentTick = tick[instruments[i].symbol]
      const currentTrade = trade[instruments[i].symbol]
      return {
        ...instruments[i],
        askPrice: askPriceFixed(currentTick, instruments[i]),
        bidPrice: bidPriceFixed(currentTick, instruments[i]),
        askVolume: currentTick?.askVolume,
        bidVolume: currentTick?.bidVolume,
        lastTrade: safePrice(currentTrade, 'price', instruments[i].priceDecimals),
        key: instruments[i].id,
      }
    }

    return instrumentsGroups[instrumentType].data.map(i => build(i)).filter(data => data !== null)
  }

  return (
    <ExternalTable theme={theme} colorMode={colorMode}>
      {/* Header */}
      <thead>
        {openFilter ? (
          <tr className="filterRow">
            <th colSpan={7} className="filter">
              <label className="filterCheckBoxLabel">
                <input
                  type="checkbox"
                  id={'selected'}
                  className="filterCheckBox"
                  onChange={e => updateFilters(e.target.checked)}
                  checked={selected}
                />
                <div className="customCheckBox"></div>
                {t('selected')}
              </label>
            </th>
          </tr>
        ) : null}
        <tr>
          <th className="checkBoxHeader"></th>
          <th></th>
          <th>{t('bidVolume')}</th>
          <th>{t('bidPrice')}</th>
          <th>{t('askPrice')}</th>
          <th>{t('askVolume')}</th>
          <th>{t('lastTrade')}</th>
        </tr>
      </thead>
      {/* Body */}
      <tbody>
        {instrumentTypesList.map((instrumentTypeId, index) => {
          return (
            <tr key={instrumentTypeId}>
              <td colSpan={7}>
                <Expander
                  expanded={expandedRows[instrumentTypeId]}
                  onClick={() => updateExpanded(instrumentTypeId)}
                  text={instrumentTypesNameList[index]}
                  theme={theme}
                />
                {/* if instrument group is expanded render instruments */}
                {expandedRows[instrumentTypeId] ? (
                  <InternalTable theme={theme}>
                    <tbody>
                      {getSubComponentData(instrumentTypeId).map(sc => (
                        <tr key={sc.key}>
                          <td className="checkBoxCell">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e =>
                                  updateSelected(
                                    instrumentTypeId,
                                    sc.exchangeId,
                                    sc.symbol,
                                    e.target.checked
                                  )
                                }
                                checked={
                                  (selectedInstruments[instrumentTypeId] &&
                                    selectedInstruments[instrumentTypeId][
                                      `${sc.exchangeId}:${sc.symbol}`
                                    ]) ||
                                  false
                                }
                              />
                              <div className="customCheckBox" />
                            </label>
                          </td>
                          <td>{sc.name}</td>
                          <td>{sc.bidVolume ? shortenNumber(sc.bidVolume) : ''}</td>
                          <td>{<Price value={sc.bidPrice} instrument={sc} />}</td>
                          <td>{<Price value={sc.askPrice} instrument={sc} />}</td>
                          <td>{sc.askVolume ? shortenNumber(sc.askVolume) : ''}</td>
                          <td>{sc.lastTrade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </InternalTable>
                ) : null}
              </td>
            </tr>
          )
        })}
      </tbody>
    </ExternalTable>
  )
}

const mapStateToProps = (
  {
    instruments: { data: instruments, groupBy: instrumentsGroups },
    marketData: { tick, trade },
    workspace: { currentWorkspace, workspaces },
  },
  ownProps
) => ({
  openFilter: workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]?.openFilter,
  expandedRows: workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]?.expandedRows,
  selectedInstruments: workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]?.selectedInstruments,
  filters: workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]?.filters,

  instruments,
  instrumentsGroups,
  tick,
  trade,
})

const mapDispatchToProps = {
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  subscribeTradeSymbol,
  unsubscribeTradeSymbol,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceList)
