/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import Close from '@material-ui/icons/Close'
import React, { memo, useState, useEffect, useContext, useMemo } from 'react'
import { ClickAwayListener, Paper } from '@material-ui/core'

import * as style from './style'
import PriceButton from '../PriceButton'
import { setPos } from '../NewSpot/style'
import PriceMovement from '../PriceMovement'
import AmountToOperate from '../AmountToOperate'
import InstrumentSearch from '../InstrumentSearch'
import { I18nContext } from '../../containers/i18n'
import { fmtDateShort } from '../../common/utilities'
import {
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  subscribeDepthSymbol,
  unsubscribeDepthSymbol,
} from '../../actions/marketData'
import {
  deleteCurrency,
  changeToViewMode,
  reorderCurrencies,
  resetCurrency,
} from '../../actions/workspace'
import MarketDepthPanel from '../MarketDepthPanel'

const spotSubtitle = (instrument, t) => {
  const subType = t(instrument.securitySubType)
  const type = !subType ? t(instrument.securityType) : ''
  const expiryDate = fmtDateShort(instrument.expiryDate)
  return `${subType} ${type} ${expiryDate}`
}

const safePrice = (currency, key, decimals) =>
  currency?.[key] ? Number(currency[key]).toFixed(decimals) : ''

let oldIndex = -1
let oldTabIndex = -1

const handleDragStart = (e, index, tabIndex) => {
  e.dataTransfer.setData('text/plain', '')
  e.stopPropagation()
  oldIndex = index
  oldTabIndex = tabIndex
}

const SpotView = ({
  spot,
  changeToViewMode,
  tick,
  instrumentDepthData,
  deleteCurrency,
  resetCurrency,
  instrument,
  isDragging,
  reorderCurrencies,
  spotIndex,
  status,
  subscribeTickSymbol,
  tabIndex,
  unsubscribeTickSymbol,
  keycloak,
  workspaces,
  currentWorkspace,
  subscribeDepthSymbol,
  unsubscribeDepthSymbol,
  withDepth = false,
}) => {
  const { t } = useContext(I18nContext)
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 })
  const isDeleting = workspaces[currentWorkspace].tabs[tabIndex].deleting || false

  const askPriceFixed = safePrice(
    withDepth ? instrumentDepthData && instrumentDepthData['1'] : tick,
    'askPrice',
    instrument.priceDecimals
  )
  const bidPriceFixed = safePrice(
    withDepth ? instrumentDepthData && instrumentDepthData['1'] : tick,
    'bidPrice',
    instrument.priceDecimals
  )
  useEffect(() => {
    if (!isDeleting) {
      !withDepth &&
        subscribeTickSymbol({ symbol: instrument.symbol, exchangeId: instrument.exchangeId })
      withDepth &&
        subscribeDepthSymbol({ symbol: instrument.symbol, exchangeId: instrument.exchangeId })
    }
    resetCurrency(spotIndex, tabIndex, false)
  }, [])

  const onSelectInstrument = (instrument, tabIndex, spotIndex) => {
    !withDepth && unsubscribeTickSymbol(instrument.symbol)
    withDepth && unsubscribeDepthSymbol(instrument.symbol)
    changeToViewMode(instrument, spotIndex, tabIndex, 'VIEW')
    !withDepth &&
      subscribeTickSymbol({ symbol: instrument.symbol, exchangeId: instrument.exchangeId })
    withDepth && subscribeDepthSymbol(instrument.symbol)
  }
  const handleDrop = (e, index, tabIndex) => {
    e.preventDefault()
    tabIndex === oldTabIndex &&
      reorderCurrencies({
        oldIndex,
        newIndex: index,
        currentTab: tabIndex,
      })
  }

  const { colorMode } = useThemeUI()

  return (
    <>
      <div sx={style.paper}>
        <Paper
          square
          draggable
          elevation={0}
          sx={style.spot}
          style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
          onDrop={e => handleDrop(e, spotIndex, tabIndex)}
          onDragStart={e => handleDragStart(e, spotIndex, tabIndex)}
          onDragOver={e => e.preventDefault()}
        >
          <div sx={style.mainTitle(colorMode)}>
            <span
              sx={style.close}
              onClick={() => {
                const total = workspaces[currentWorkspace].tabs[tabIndex].spots.length
                resetCurrency(spotIndex, tabIndex, total - 1 !== spotIndex)
                deleteCurrency(spotIndex, tabIndex)
                !withDepth && unsubscribeTickSymbol(instrument.symbol)
                withDepth && unsubscribeDepthSymbol(instrument.symbol)
              }}
            >
              <Close sx={{ fontSize: 3 }} />
            </span>
            <span
              sx={style.currency(colorMode)}
              onDoubleClick={e => {
                const { top, left } = e.target.offsetParent.getBoundingClientRect()
                setPosition({ y: top + 4, x: left + 70 })
                changeToViewMode('', spotIndex, tabIndex)
              }}
            >
              {instrument.symbol}
              <br />
              <div sx={style.title}>{spotSubtitle(instrument, t)}</div>
            </span>
          </div>
          <div sx={style.container}>
            <PriceButton
              priceData={bidPriceFixed}
              lastPriceData={
                withDepth ? instrumentDepthData['1']?.lastBidPrice || '' : tick?.lastBidPrice || ''
              }
              currency={instrument.symbol}
              instrument={instrument}
              tabIndex={tabIndex}
              spotIndex={spotIndex}
              keycloak={keycloak}
            />
            <PriceButton
              priceData={askPriceFixed}
              lastPriceData={
                withDepth ? instrumentDepthData['1']?.lastAskPrice || '' : tick?.lastAskPrice || ''
              }
              currency={instrument.symbol}
              instrument={instrument}
              tabIndex={tabIndex}
              spotIndex={spotIndex}
              keycloak={keycloak}
              ask
            />
            <PriceMovement bid={bidPriceFixed} ask={askPriceFixed} instrument={instrument} />
          </div>
          {useMemo(
            () => (
              <div sx={style.amount}>
                <AmountToOperate
                  currencyData={spot}
                  spotIndex={spotIndex}
                  tabIndex={tabIndex}
                  instrument={instrument}
                />
              </div>
            ),
            [spot, spotIndex, tabIndex, instrument]
          )}
          {withDepth ? (
            <div>
              <MarketDepthPanel
                instrument={instrument}
                depth={5}
                currency={instrument.symbol}
                tabIndex={tabIndex}
                spotIndex={spotIndex}
                keycloak={keycloak}
                instrumentDepthData={instrumentDepthData}
              />
            </div>
          ) : null}
        </Paper>
      </div>
      {status === 'EDIT' && (
        <div key="2" sx={setPos([x, y])}>
          <ClickAwayListener onClickAway={() => changeToViewMode('', spotIndex, tabIndex)}>
            <InstrumentSearch
              search={spot.value}
              spotIndex={spotIndex}
              tabIndex={tabIndex}
              onSelectInstrument={onSelectInstrument}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (
  {
    workspace: { workspaces, currentWorkspace },
    instruments: { data: instruments },
    marketData: { tick, depth },
  } = state,
  ownProps
) => {
  const currentData = workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]

  if (!ownProps.withDepth) {
    return {
      spot: currentData?.spots[ownProps.spotIndex],
      workspaces,
      currentWorkspace,
      instrument: instruments[currentData?.spots[ownProps.spotIndex].id],
      tick: tick[currentData?.spots[ownProps.spotIndex].value],
    }
  }

  return {
    spot: currentData?.spots[ownProps.spotIndex],
    workspaces,
    currentWorkspace,
    instrument: instruments[currentData?.spots[ownProps.spotIndex].id],
    tick: tick[currentData?.spots[ownProps.spotIndex].value],
    instrumentDepthData: depth[currentData?.spots[ownProps.spotIndex]?.value] || {},
  }
}

const mapDispatchToProps = {
  deleteCurrency,
  resetCurrency,
  changeToViewMode,
  reorderCurrencies,
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  subscribeDepthSymbol,
  unsubscribeDepthSymbol,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(SpotView))
