export const statusContainer = {
  textAlign: 'center',
  paddingLeft: '15px',
  paddingRight: '15px',
  height: '34px',
}

export const defaultStatus = {
  minWidth: '100px',
  border: 'solid 5px',
  backgroundColor: 'grey',
  borderColor: 'grey',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}
export const newStatus = {
  ...defaultStatus,
  backgroundColor: 'green',
  borderColor: 'green',
}

export const filledStatus = {
  ...defaultStatus,
  backgroundColor: 'accent',
  borderColor: 'accent',
}

export const pendingStatus = {
  ...defaultStatus,
  backgroundColor: 'red',
  borderColor: 'red',
}

export const rejectedStatus = {
  ...defaultStatus,
  backgroundColor: 'red',
  borderColor: 'red',
}

export const cancelledStatus = {
  ...defaultStatus,
  backgroundColor: 'grey',
  borderColor: 'grey',
}
export const replacerejectedStatus = {
  ...cancelledStatus,
}

export const partiallyfilledStatus = {
  ...defaultStatus,
  color: 'text',
  backgroundColor: 'none',
  borderColor: 'accent',
}

export const pendingnewStatus = {
  ...defaultStatus,
  color: 'text',
  backgroundColor: 'none',
  borderColor: 'grey',
}

export const pendingcancelStatus = {
  ...defaultStatus,
  color: 'text',
  backgroundColor: 'none',
  borderColor: 'grey',
}

export const pendingreplaceStatus = {
  ...defaultStatus,
  color: 'text',
  backgroundColor: 'none',
  borderColor: 'grey',
}

export const statusText = {
  lineHeight: '1.8em',
}

export const modalTitle = {
  paddingBottom: '3px',
}

export const textField = {
  width: '250px',
  fontWeight: 'bold',
}

export const disabled = {
  color: 'grey',
}

export const selectColumn = {
  backgroundColor: '#283e60',
  color: 'white',
  paddingLeft: '5px',
}

export const selectColumnLabel = {
  color: 'white',
}

export const selectColumnStyle = {
  width: '250px',
  paddingLeft: '20px',
}

export const filters = {
  padding: '5px',
}

export const filterColumns = {
  height: '600px',
}

export const customButton = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
}

export const filterListIcon = {
  cursor: 'pointer',
}

export const listItem = {
  color: 'white',
}

export const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
}

export const menu = {
  backgroundColor: 'accent',
  '&:hover': {
    backgroundColor: 'primary',
  },
  '&:focus': {
    backgroundColor: 'accent',
  },
}

export const container = {
  marginTop: '0px',
}

export const differenceCell = { fontWeight: 'bold' }
