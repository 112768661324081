/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import * as CustomTheme from './style'

import cn from 'classnames'
import { connect } from 'react-redux'

import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  AppBar,
  Drawer,
  Divider,
  Toolbar,
  IconButton,
  withStyles,
  ClickAwayListener,
  Button,
  Link,
} from '@material-ui/core'

import style from './style.js'
import { I18nContext } from '../i18n'
import MainLayout from '../MainLayout'
import { Widgets, UserMenu, UserSettings, WorkspaceNavBar, IconSvg } from '../../components'
import { closeErrorDialog, changeCurrentAccount } from '../../actions/workspace'
import ModalMessage from '../../components/ModalMessage'
import AccountGroupsSearch from '../../components/AccountGroupsSearch'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ModalAccountGroup from '../../components/ModalAccountGroup'
import ModalMessageNotification from '../../components/ModalMessageNotification'
import PositionCalculator from '../PositionCalculator'
import DropdownButton from '../../components/DropdownButton'
import { MenuItem } from '../../common/utilities'

const BtnOpenMenu = () => <IconSvg name={'burgerBtn'} style={CustomTheme.menuButtonStyle} />
const BtnHideMenu = () => <IconSvg name={'leftArrow'} style={CustomTheme.menuButtonStyle} />
const Logo = () => <IconSvg name={'logo'} style={CustomTheme.logo} />
const LogoBm = () => <IconSvg name={'logoBm'} style={CustomTheme.logoBm} />
const LogoBmeDark = () => <IconSvg name={'logoBmeDark'} style={CustomTheme.logoBmeDark} />
const LogoIgf = () => <IconSvg name={'logoIgf'} style={CustomTheme.logoIgf} />
const LogoMtx = () => <IconSvg name={'logoMtx'} style={CustomTheme.logoMtx} />
const BtnMenuWidget = () => <IconSvg name={'menuWidget'} style={CustomTheme.anyadirMenu} />

const Workspace = ({
  classes,
  keycloak,
  isSettingsOpen,
  currentWorkspace,
  modalMessage,
  currentAccount,
  accountGroups,
  accounts,
  accountIds,
  closeErrorDialog,
  theme: themeMui,
  changeCurrentAccount,
}) => {
  const sectionRef = useRef(null)
  const { t } = useContext(I18nContext)
  const [open, setOpen] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [anchorKid, setAnchorKid] = useState(null)
  const [workspaceIndex, setWorkspace] = useState(currentWorkspace)
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 })
  const [isOpenSelectAccount, setIsOpenSelectAccount] = useState(false)
  const [isOpenModalAccountGroup, setIsOpenModalAccountGroup] = useState(false)
  const [editAccountGroup, setEditAccountGroup] = useState()

  useEffect(() => {
    workspaceIndex !== currentWorkspace && setWorkspace(currentWorkspace)
  }, [currentWorkspace])

  const handleChangeAccount = accountGroup => {
    setIsOpenSelectAccount(false)
    changeCurrentAccount(accountGroup)
  }
  const handleEditAccountGroup = accountGroup => {
    setIsOpenSelectAccount(false)
    setIsOpenModalAccountGroup(true)
    setEditAccountGroup(accountGroup)
  }

  const findNextAccount = id => {
    let acc = accountGroups.filter(accGrp => accGrp.id !== id)
    if (acc.length) {
      return { ...acc[0] }
    }
    acc = accountIds.length && {
      id: accounts[accountIds[0]].id,
      name: accounts[accountIds[0]].name,
      type: 'account',
    }
    if (acc) {
      return acc
    }
    return {}
  }

  const getLogo = selectedTheme => {
    if (selectedTheme === 'bm') {
      return <LogoBm />
    } else if (selectedTheme === 'igf') {
      return (
        <div>
          <LogoBmeDark />
          <LogoIgf />
          <LogoMtx />
        </div>
      )
    } else {
      return <Logo />
    }
  }

  const { theme, colorMode } = useThemeUI()

  useEffect(() => {
    document.body.className = colorMode
  }, [theme])

  return (
    <div sx={CustomTheme.root}>
      <AppBar
        position="absolute"
        sx={{
          ...CustomTheme.topBar,
          ...CustomTheme.appBar(themeMui),
          ...(open && CustomTheme.appBarShift(themeMui)),
        }}
        elevation={0}
      >
        <Toolbar disableGutters={true}>
          <div
            data-test-id="view-workspaces"
            onClick={() => setOpen(!open)}
            sx={CustomTheme.menuButtonBackground}
          >
            {!open ? <BtnOpenMenu /> : <BtnHideMenu />}
          </div>
          <div>{getLogo(colorMode)}</div>
          <div
            sx={CustomTheme.account}
            onClick={e => {
              const { top, left } = e.currentTarget.getBoundingClientRect()
              setPosition({ x: left, y: top })
              setIsOpenSelectAccount(true)
            }}
          >
            <div sx={CustomTheme.accountList(colorMode)}>
              {currentAccount?.name || t('selectAccountGroupsName')}
            </div>
            <div sx={CustomTheme.moreAccounts(colorMode)}>
              <ExpandMore />
            </div>
          </div>

          <div sx={CustomTheme.kidButton}>
            <DropdownButton
              label={'Kid'}
              buttonType={'navButton'}
              onClick={e => setAnchorKid(e.currentTarget)}
              anchorEl={anchorKid}
              onClose={() => setAnchorKid(null)}
            >
              <MenuItem
                component={Link}
                target="_blank"
                onClick={() => setAnchorKid(null)}
                href={t('kidBoughtxRollingFutureUrl')}
              >
                {t('kidBoughtxRollingFuture')}
              </MenuItem>
              <MenuItem
                component={Link}
                target="_blank"
                onClick={() => setAnchorKid(null)}
                href={t('kidSoldxRollingFutureUrl')}
              >
                {t('kidSoldxRollingFuture')}
              </MenuItem>
            </DropdownButton>
          </div>

          <div sx={CustomTheme.hedgingSim}>
            <Button
              size="small"
              href={t('hedgingSimUrl')}
              target="_blank"
              sx={{ variant: `buttons.navButton` }}
            >
              {t('hedgingSim')}
            </Button>
          </div>

          <div sx={CustomTheme.addWorkspace}>
            <IconButton
              aria-owns={!!anchor && 'menu-appbar'}
              aria-haspopup="true"
              data-test-id="add-widget"
              onClick={e => setAnchor(e.currentTarget)}
              color="inherit"
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            >
              <BtnMenuWidget />
            </IconButton>
            <Widgets
              open={!!anchor}
              anchorEl={anchor}
              onClose={() => setAnchor(null)}
              addNewWorkspace={true}
              createSection={sectionRef.current}
            />
          </div>
          <div sx={CustomTheme.logout}>
            <UserMenu keycloak={keycloak} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        variant="permanent"
        sx={{
          paper: {
            ...(open ? CustomTheme.drawerPaper(themeMui) : CustomTheme.drawerPaperClose(themeMui)),
          },
        }}
        classes={{ paper: cn(classes.drawerPaper, { [classes.drawerPaperClose]: !open }) }}
      >
        <div sx={CustomTheme.toolbar(themeMui)} />
        <Divider sx={{ backgroundColor: 'transparent' }} />
        <WorkspaceNavBar isOpen={open} keycloak={keycloak} />
        <Divider sx={{ backgroundColor: 'transparent' }} />
      </Drawer>

      <main sx={{ ...CustomTheme.content(themeMui), backgroundColor: 'background' }}>
        <div sx={CustomTheme.toolbar(themeMui)} />
        {workspaceIndex === currentWorkspace && (
          <MainLayout keycloak={keycloak} addSection={data => (sectionRef.current = data)} />
        )}
        <ModalMessage {...modalMessage} onClose={closeErrorDialog} open={modalMessage.open} />
        <ModalMessageNotification />
        <PositionCalculator />
      </main>
      <UserSettings open={isSettingsOpen} />
      <div>
        {isOpenSelectAccount && (
          <div sx={CustomTheme.setPos([x, y])}>
            <ClickAwayListener onClickAway={() => setIsOpenSelectAccount(false)}>
              <AccountGroupsSearch
                account={currentAccount?.name}
                changeCurrentAccount={handleChangeAccount}
                onEditAccountGroup={handleEditAccountGroup}
              />
            </ClickAwayListener>
          </div>
        )}
      </div>

      <ModalAccountGroup
        data={editAccountGroup}
        onClose={() => setIsOpenModalAccountGroup(false)}
        open={isOpenModalAccountGroup}
        token={keycloak.token}
        onAdd={account =>
          changeCurrentAccount({ id: account.id, name: account.name, type: account.type })
        }
        onUpdate={account =>
          currentAccount.id === account.id &&
          changeCurrentAccount({ id: account.id, name: account.name, type: account.type })
        }
        onDelete={id => currentAccount.id === id && changeCurrentAccount(findNextAccount(id))}
      />
    </div>
  )
}

const mapStateToProps = ({
  workspace: { isSettingsOpen, currentWorkspace, modalMessage, currentAccount, accountGroups },
  accounts: { data: accounts, dataIds: accountIds },
}) => ({
  isSettingsOpen,
  currentWorkspace,
  modalMessage,
  currentAccount,
  accountGroups,
  accounts,
  accountIds,
})
const mapDispatchToProps = { closeErrorDialog, changeCurrentAccount }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style, { withTheme: true })(Workspace))
