import React from 'react'
import style from './style.css'

import ArrowUp from './ArrowUp'
import ArrowDown from './ArrowDown'

const fill = (isHovered, color) => (isHovered ? 'white' : color)

const SplitQuoteArrow = ({ priceData, lastPriceData, hover }) => (
  <div className={style.priceArrowMovement}>
    {priceData > lastPriceData ? (
      <ArrowUp fill={fill(hover, '#81d31c')} />
    ) : (
      <ArrowDown fill={fill(hover, '#d90a0a')} />
    )}
  </div>
)

export default SplitQuoteArrow
