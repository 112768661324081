/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'
import * as CustomTheme from './style'

import React, { useState, useEffect, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import FlexLayout from 'flexlayout-react'
import { filter, map, pipe, propEq } from 'ramda'
import { Add, FilterList, Settings, CloudDownload } from '@material-ui/icons'

import style from './style.css'

import {
  Rfq,
  RfqList,
  NewSpot,
  Widgets,
  Loading,
  SpotView,
  OrderList,
  Modal,
  TradeList,
  QuoteList,
  PriceList,
  TradePanel,
  PositionList,
  AccountSummaryList,
  AccountGroup,
  MarketDepth,
} from '../components'
import { I18nContext } from '../containers/i18n'
import { defaultLayout } from '../reducers/workspace'
import { camelCase, upperFirst } from 'lodash'

import {
  unsubscribeDepthSymbol,
  unsubscribeTickSymbol,
  unsubscribeTradeSymbol,
} from '../actions/marketData'
import {
  closeConfirmDialog,
  createLayout,
  updateCurrentLayout,
  removeTab,
  reorderCurrencies,
  deleteCurrency,
  addTableTab,
  addCurrencyTab,
  addRfqTab,
  addTradePanel,
  saveWorkspace,
  addOrderListPanel,
  addPositionListPanel,
  addMarketDepthPanel,
  addAccountSummaryListPanel,
  addAccountGroupPanel,
  addTradeListPanel,
  addQuoteListPanel,
  addPriceListPanel,
  setOpenFilter,
  setOpenAccessor,
  saveActiveTab,
} from '../actions/workspace'
import { setDownloadCsv } from '../actions/csv'
import { widgetsTypes as types, widgetsEnum } from '../common/enum'
import { reducePositionTabs } from '../actions/positions'

const muted = { bg: 'muted' }
const notActive = { bg: 'muted', color: 'grey' }

const iconButton = colorMode => ({
  marginLeft: '5px',
  width: '17px',
  paddingRight: '3px',
  paddingLeft: '3px',
  zIndex: '999',
  width: '17px',
  height: theme => theme.heights.iconButton[colorMode],
  marginTop: '-2px',
})

const downloadStyle = colorMode => ({
  ...iconButton(colorMode),
  paddingRight: '4px',
  paddingLeft: '4px',
})

const iconStyle = (opened, colorMode) => {
  return { ...iconButton(colorMode), ...(opened && { backgroundColor: 'accent' }) }
}

const getTabNumberFromName = tabName => /\d+$/gm.exec(tabName) //returns the numbers at the end

const getTabsNumberOfType = (typeTab, tabs) => {
  const nums = pipe(
    filter(propEq('type', typeTab)),
    map(({ name }) => getTabNumberFromName(name)),
    map(e => (e ? +e[0] : 0))
  )(tabs)
  const maxNumber = Math.max(...nums.map(x => +x || 0))

  //return the fisrt missing integer
  for (var i = 0; i <= maxNumber; i++) {
    if (nums.indexOf(i) === -1) {
      return +i
    }
  }

  //return the next integer
  return nums?.length > 0 ? maxNumber + 1 : 0
}

const tabHasFilters = attr => attr._visible && widgetsEnum[attr._attributes.component]?.hasFilters

const tabHasSettings = attr => attr._visible && widgetsEnum[attr._attributes.component]?.hasSettings

const tabHasDownloads = attr =>
  attr._visible && widgetsEnum[attr._attributes.component]?.hasDownloads

const getTabLiteral = type => widgetsEnum[type]?.literal

const MainLayout = ({
  addCurrencyTab,
  addOrderListPanel,
  addRfqTab,
  addSection,
  addTableTab,
  addTradeListPanel,
  addQuoteListPanel,
  addPriceListPanel,
  addTradePanel,
  createLayout,
  closeConfirmDialog,
  deleteCurrency,
  keycloak,
  removeTab,
  reorderCurrencies,
  saveActiveTab,
  setOpenAccessor,
  setOpenFilter,
  setDownloadCsv,
  unsubscribeTickSymbol,
  unsubscribeDepthSymbol,
  updateCurrentLayout,
  workspace,
  workspace: { modal, workspaces, currentWorkspace },
  workspaceFetched,
  saveWorkspace,
  unsubscribeTradeSymbol,
  positions,
  instruments,
  addPositionListPanel,
  addAccountSummaryListPanel,
  addMarketDepthPanel,
  addAccountGroupPanel,
  accounts,
  currentAccount,
  reducePositionTabs,
}) => {
  const ref = useRef()
  const { t } = useContext(I18nContext)
  const [cancelAdd, setCancel] = useState(false)
  const [anchorEl, setAnchor] = useState(null)
  const [currentTheme] = useColorMode()

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  let model = null
  const [tabSetId, setTabSetId] = useState(null)
  let activeTab = null
  let currentWorkspaceIndex = currentWorkspace

  if (workspaces[currentWorkspace].layout) {
    model = FlexLayout.Model.fromJson(workspaces[currentWorkspace].layout)
  } else {
    createWorkspaceLayout()
  }

  // #region
  // getNumberOfTabs is a function that computes the next num
  // getNumberOfTabs :: [Tabs] -> Int
  const getNumberOfTabs = typeTab =>
    getTabsNumberOfType(typeTab, workspaces[currentWorkspace]?.tabs)
  const handleKeyDown = ({ key }) => {
    if (key === 'cancelAdd') {
      setCancel(true)
      ref.current.onCancelAdd()
    }
  }
  const handleOpen = (e, id, type) => {
    const { top: currentTop, left, width } = e.currentTarget.getBoundingClientRect()
    const top = currentTop > window.innerHeight - 340 ? currentTop - 324 : currentTop + width - 1
    const tabIndex = workspaces[currentWorkspace].tabs.findIndex(propEq('key', +id.substring(1)))
    const openFilter = workspaces[currentWorkspace].tabs[tabIndex]?.openFilter
    type === 'filter'
      ? setOpenFilter({ openFilter: !openFilter, tabIndex })
      : setOpenAccessor({ openAccessor: true, tabIndex, left, top })
    e.stopPropagation()
  }
  const createWorkspaceLayout = () => {
    const activeWorkspace = workspaces[currentWorkspace]
    const layout = defaultLayout
    activeWorkspace.tabs.map(tab => {
      layout.layout.children[0].children.push({
        type: tab.type,
        name: tab.name,
        children: [],
        component: tab.type,
      })
    })
    createLayout(layout, workspace.currentWorkspace)
  }

  const handleDownload = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    const tabIndex = workspaces[currentWorkspace].tabs.findIndex(propEq('key', +id.substring(1)))
    setDownloadCsv({ download: true, currentWorkspace, tabIndex })
  }

  const selectSection = typeTab => t(`add${upperFirst(camelCase(typeTab))}`)

  const addInstrument = (typeTab, name, acc, dataRfq) => {
    const key = +ref.current.tabIds[ref.current.tabIds.length - 1].substring(1)
    switch (typeTab) {
      case types.SPLIT_QUOTE:
      case types.DEPTH_SPLIT_QUOTE:
        addCurrencyTab({ key, name, typeTab })
        break
      case types.TRADE:
        addTradePanel({ key, name })
        break
      case types.ORDER_LIST:
        addOrderListPanel({ key, name })
        break
      case types.TRADE_LIST:
        addTradeListPanel({ key, name })
        break
      case types.PRICE_LIST:
        addPriceListPanel({ key, name })
        break
      case types.QUOTE_LIST:
        addQuoteListPanel({ key, name })
        break
      case types.POSITION_LIST:
      case types.ACCOUNT_POSITION_LIST:
        addPositionListPanel({ key, name, typeTab })
        break
      case types.ACCOUNT_SUMMARY_LIST:
        addAccountSummaryListPanel({ key, name })
        break
      case types.ACCOUNT_GROUP:
        addAccountGroupPanel({ key, name })
        break
      case types.MARKET_DEPTH:
        addMarketDepthPanel({ key, name })
        break
      default:
        break
    }
  }

  const handleClose = () => setAnchor(null)

  const getTabName = typeTab => {
    const number = getNumberOfTabs(typeTab)
    const numberStr = number ? ` ${number}` : ''
    return `${t(getTabLiteral(typeTab))}${numberStr}`
  }

  const onAddClick = (typeTab, acc, dataRfq) => {
    const name = getTabName(typeTab)
    const event = `document.dispatchEvent(new&nbsp;KeyboardEvent('keydown',{key:'cancelAdd'}))`
    ref.current.addTabWithDragAndDropIndirect(
      `<div class=${style.dragText} onClick=${event}></div>${t('add')} ${selectSection(typeTab) +
        t('dragText')} `,
      {
        component: typeTab,
        name,
      },
      () =>
        setTimeout(() => {
          if (!cancelAdd) {
            addInstrument(typeTab, name, acc, dataRfq)
          }
          setCancel(false)
        })
    )
  }

  const onAddClickOnTabSet = (typeTab, acc, dataRfq) => {
    const name = getTabName(typeTab)
    ref.current.addTabToTabSet(tabSetId, {
      component: typeTab,
      name,
    })
    setTimeout(() => {
      addInstrument(typeTab, name, acc, dataRfq)
    })
  }

  addSection(onAddClick)

  function unsubscribeFrom(typeTab, unsubscribe) {
    const { filters, selectedInstruments, expandedRows } = typeTab

    Object.entries(expandedRows).forEach(expandedInstrumentByType => {
      /* Remove subscriptions of expanded instruments */
      if (expandedInstrumentByType[1]) {
        /* Remove subscriptions of visibles and selected instruments */
        if (filters?.selected) {
          Object.entries(selectedInstruments[expandedInstrumentByType[0]]).forEach(
            selectedInstrument => {
              if (selectedInstrument[1]) {
                unsubscribe(instruments.data[selectedInstrument[0]].symbol)
              }
            }
          )
        } else {
          /* Remove all subscriptions from all visible instruments */
          instruments &&
            instruments.groupBy &&
            instruments.groupBy[expandedInstrumentByType[0]] &&
            instruments.groupBy[expandedInstrumentByType[0]].data.forEach(instrumentId =>
              unsubscribe(instruments.data[instrumentId].symbol)
            )
        }
      }
    })
  }

  const makeAction = action => {
    if (action.type !== 'FlexLayout_SetActiveTabset') {
      if (action.type === 'FlexLayout_DeleteTab') {
        const index = +action.data.node.substring(1)
        const typeTab = workspaces[currentWorkspace].tabs.find(propEq('key', index))

        switch (typeTab?.type) {
          case types.SPLIT_QUOTE:
            typeTab.spots.map(spot => unsubscribeTickSymbol(spot.value))
            break
          case types.DEPTH_SPLIT_QUOTE:
            typeTab.spots.map(spot => unsubscribeDepthSymbol(spot.value))
            break
          case types.POSITION_LIST:
          case types.ACCOUNT_POSITION_LIST:
          case types.ACCOUNT_SUMMARY_LIST:
            reducePositionTabs()
            break
          case types.PRICE_LIST:
            unsubscribeFrom(typeTab, symbol => {
              unsubscribeTickSymbol(symbol)
              unsubscribeTradeSymbol(symbol)
            })
            break
          case types.QUOTE_LIST:
            unsubscribeFrom(typeTab, symbol => {
              unsubscribeTickSymbol(symbol)
            })
            break
          case types.TRADE:
            typeTab.selectedInstrumentSymbol &&
              unsubscribeTradeSymbol(typeTab.selectedInstrumentSymbol)
            break
          default:
            break
        }
        removeTab({ index })
      } else if (action.type === 'FlexLayout_SelectTab') {
        activeTab = +action.data.tabNode.substring(1)
        saveActiveTab(currentWorkspace, activeTab)
      }

      updateCurrentLayout({
        layout: model.toJson().layout,
        workspaceIndex: currentWorkspace,
      })
    }
  }

  useEffect(() => {
    updateServerData()
  }, [workspace])

  const [updateServerDataTimeOut, setUpdateServerDataTimeOut] = useState()
  const updateServerData = () => {
    clearTimeout(updateServerDataTimeOut)
    setUpdateServerDataTimeOut(
      setTimeout(() => {
        keycloak.updateToken(30).success(() => {
          saveWorkspace({
            token: keycloak.token,
            data: workspace,
          })
        })
      }, 1000)
    )
  }

  const factory = node => {
    const tabKey = +node.getId().substring(1)
    const currentTabs = workspaces[currentWorkspace].tabs

    if (currentWorkspaceIndex !== currentWorkspace) {
      if (workspaces[currentWorkspace].layout) {
        model = FlexLayout.Model.fromJson(workspaces[currentWorkspace].layout)
      } else {
        createWorkspaceLayout()
      }
      currentWorkspaceIndex = currentWorkspace
    }

    const currentTab = currentTabs.length ? currentTabs.find(propEq('key', tabKey)) : {}
    const tabIndex = workspaces[currentWorkspace].tabs.findIndex(propEq('key', tabKey))

    if (!currentTab) {
      return false
    }

    switch (currentTab.type) {
      case types.SPLIT_QUOTE:
        return (
          <div className={style.spotsContainer}>
            {currentTab.spots.map((currency, index) => (
              <SpotView
                key={`${currency.key}${index}`}
                spotIndex={index}
                tabIndex={tabIndex}
                reorderCurrencies={reorderCurrencies}
                deleteCurrency={deleteCurrency}
                keycloak={keycloak}
              />
            ))}
            <NewSpot tabIndex={tabIndex} />
          </div>
        )
      case types.DEPTH_SPLIT_QUOTE:
        return (
          <div className={style.spotsContainer}>
            {currentTab.spots.map((currency, index) => (
              <SpotView
                key={`${currency.key}${index}`}
                spotIndex={index}
                tabIndex={tabIndex}
                reorderCurrencies={reorderCurrencies}
                deleteCurrency={deleteCurrency}
                keycloak={keycloak}
                withDepth
              />
            ))}
            <NewSpot tabIndex={tabIndex} />
          </div>
        )
      case types.TRADE:
        return <TradePanel keycloak={keycloak} tabIndex={tabIndex} />
      case types.ORDER_LIST:
        return <OrderList keycloak={keycloak} tabIndex={tabIndex} />
      case types.TRADE_LIST:
        return <TradeList keycloak={keycloak} tabIndex={tabIndex} />
      case types.PRICE_LIST:
        return <PriceList keycloak={keycloak} tabIndex={tabIndex} />
      case types.QUOTE_LIST:
        return <QuoteList keycloak={keycloak} tabIndex={tabIndex} />
      case types.POSITION_LIST:
        return (
          <PositionList
            keycloak={keycloak}
            tabIndex={tabIndex}
            data={currentTab}
            allAccounts={true}
          />
        )
      case types.ACCOUNT_POSITION_LIST:
        return <PositionList keycloak={keycloak} tabIndex={tabIndex} />
      case types.ACCOUNT_SUMMARY_LIST:
        return <AccountSummaryList keycloak={keycloak} tabIndex={tabIndex} />
      case types.ACCOUNT_GROUP:
        return <AccountGroup keycloak={keycloak} tabIndex={tabIndex} />
      case types.MARKET_DEPTH:
        return <MarketDepth />
      default:
        return false
    }
  }
  const handleMenu = (event, tabSetNode) => {
    setAnchor(event.currentTarget)
    setTabSetId(tabSetNode.getId())
  }

  // #endregion
  return workspaceFetched ? (
    <div className={style.layout}>
      <FlexLayout.Layout
        ref={ref}
        model={model}
        factory={factory}
        onAction={action => {
          model.doAction(action)
          makeAction(action)
        }}
        onRenderTabSet={(tabSetNode, { buttons }) => {
          return buttons.push(
            <a key={tabSetNode.getId()} onClick={e => handleMenu(e, tabSetNode)}>
              <Add fontSize="small" sx={CustomTheme.add} />
            </a>
          )
        }}
        classNameMapper={defaultClassName => {
          return `${defaultClassName} ${defaultClassName}-${currentTheme}`
        }}
        onRenderTab={(attr, info) => {
          const { tabs } = workspaces[currentWorkspace]
          const tabIndex = tabs.findIndex(propEq('key', +attr._attributes.id.substring(1)))
          const openAccessor = tabs[tabIndex]?.openAccessor
          const openFilter = tabs[tabIndex]?.openFilter

          const num = getTabNumberFromName(attr._attributes.name)
          const numStr = num ? ` ${num}` : ''
          let tabName = ''
          if (attr._attributes.component === 'ACCOUNT_GROUP') {
            tabName = currentAccount
              ? currentAccount.type === 'account'
                ? `${t('accountOperationAccountLabel')}: ${currentAccount.name || ''} `
                : `${t('accountGroupLabel')}: ${currentAccount.name || ''} `
              : t('accountGroupLabel')
          } else {
            tabName = getTabLiteral(attr._attributes.component)
          }

          const name = tabName
            ? `${t(tabName)}${numStr}`
            : // Support for tabs created before this fork
              attr._attributes.name

          info.content = (
            <div sx={{ ...CustomTheme.infoContent, ...(attr._visible ? muted : notActive) }}>
              <label>{name}</label>
              {tabHasDownloads(attr) && (
                <CloudDownload
                  sx={downloadStyle(currentTheme)}
                  onMouseDown={e => attr._visible && handleDownload(e, attr._attributes.id)}
                />
              )}
              {tabHasFilters(attr) && (
                <FilterList
                  sx={iconStyle(openFilter, currentTheme)}
                  onMouseDown={e => attr._visible && handleOpen(e, attr._attributes.id, 'filter')}
                />
              )}
              {tabHasSettings(attr) && (
                <Settings
                  sx={iconStyle(openAccessor, currentTheme)}
                  onMouseDown={e => attr._visible && handleOpen(e, attr._attributes.id, 'accessor')}
                />
              )}
            </div>
          )
        }}
      />
      <Widgets
        addNewWorspace={false}
        anchorEl={anchorEl}
        onAddClickOnTabSet={onAddClickOnTabSet}
        onClose={handleClose}
        open={!!anchorEl}
      />
      {modal.open && <Modal {...modal} onCancel={closeConfirmDialog} accounts={accounts} />}
    </div>
  ) : (
    <Loading />
  )
}

const mapStateToProps = ({
  modal,
  workspace,
  workspace: { fetched: workspaceFetched, currentAccount },
  positions,
  instruments,
  accounts: { data: accounts },
}) => ({
  modal,
  workspaceFetched,
  workspace,
  positions,
  instruments,
  accounts,
  currentAccount,
})

const mapDispatchToProps = {
  closeConfirmDialog,
  createLayout,
  updateCurrentLayout,
  removeTab,
  reorderCurrencies,
  deleteCurrency,
  addTableTab,
  addCurrencyTab,
  addRfqTab,
  addTradePanel,
  saveWorkspace,
  addOrderListPanel,
  addTradeListPanel,
  addQuoteListPanel,
  addPriceListPanel,
  addPositionListPanel,
  addMarketDepthPanel,
  addAccountSummaryListPanel,
  addAccountGroupPanel,
  unsubscribeTickSymbol,
  unsubscribeTradeSymbol,
  unsubscribeDepthSymbol,
  setOpenFilter,
  setOpenAccessor,
  saveActiveTab,
  reducePositionTabs,
  setDownloadCsv,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
