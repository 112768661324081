import Api from '../common/api'
import config from '../common/config'

export function fetchMarkets(token) {
  const api = new Api({
    baseUrl: config().urls.baseUrl,
    defaultOptions: { headers: { Authorization: `Bearer ${token}` } },
  })
  return {
    type: 'FETCH_MARKETS',
    payload: api.get('/markets/v1/markets'),
  }
}
