import { any, append, ifElse, lensProp, map, mergeLeft, over, propEq, set, when } from 'ramda'

const defaultModal = {
  open: false,
  viewMode: false,
  title: '',
  message: '',
  order: true,
  data: {},
  action: {},
  status: '',
}

const defaultState = {
  connected: false,
  orderList: [],
  tradeList: [],
  modal: defaultModal,
}

const defaultOrderData = { fetched: false, data: null }

const eqOrder = ({ masterOrderId }) => propEq('masterOrderId', masterOrderId)
const modify = payload =>
  ifElse(
    any(eqOrder(payload)),
    map(when(eqOrder(payload), mergeLeft(payload))),
    append({ ...payload, updateDate: payload.date })
  )

export default (state = defaultState, { type, payload } = {}) => {
  const lensModal = lensProp('modal')
  const lensOrderList = lensProp('orderList')
  const lensOrder = lensProp('order')
  const lensTrade = lensProp('tradeList')
  switch (type) {
    case 'ORDER_SOCKET_CONNECTED':
      return set(lensProp('connected'), true, state)
    case 'ORDER_UPDATE':
      return over(lensOrderList, modify(payload), state)
    case 'TRADE_UPDATE':
      return over(lensTrade, modify(payload), state)
    case 'ORDER_LIST_DATA':
      return set(lensOrderList, payload, state)
    case 'GET_ORDER_DATA_FULLFILLED':
      return set(lensOrder, { fetched: true, data: payload }, state)
    case 'GET_ORDER_DATA_PENDING':
    case 'GET_ORDER_DATA_CLEAN':
      return set(lensOrder, defaultOrderData, state)
    case 'TRADE_LIST_DATA':
      return set(lensTrade, payload, state)
    case 'SHOW_ORDER_DIALOG':
      return over(lensModal, mergeLeft({ ...payload, open: true }), state)
    case 'CLOSE_ORDER_DIALOG':
      return set(lensModal, defaultModal, state)
    default:
      return state
  }
}
