const range = (min, max) => ({ min: min, max: max })
const validNumberRange = range(100, 4294967295)
const inRange = (num, { min, max }) => num >= min && num <= max
const inValidNumberRange = num => inRange(num, validNumberRange)

export const orkValidate = ({ clientId, decisorId, executorId, tradingCapacity }) => {
  const tc = tradingCapacity?.toUpperCase()

  const validations = [
    {
      isValid: inRange(+clientId, range(0, 2)) || inValidNumberRange(+clientId),
      messageLiteral: 'clientIdRange',
    },
    {
      isValid: +decisorId === 0 || inValidNumberRange(+decisorId),
      messageLiteral: 'decisorIdZeroOrInteger',
    },
    {
      isValid: +executorId === 3 || inValidNumberRange(+executorId),
      messageLiteral: 'executorIdThreeOrInteger',
    },
    {
      isValid: +clientId !== 0 || (+decisorId >= 100 && ['DEAL', 'MATCH'].includes(tc)),
      messageLiteral: 'whenClientIdIsZero',
    },
    {
      isValid: +clientId === 0 || ['MATCH', 'ANY_OTHER'].includes(tc),
      messageLiteral: 'whenClientIdIsNotZero',
    },
    {
      isValid: !['DEAL'].includes(tc) || +clientId === 0, //condición complementaria a la anterior
      messageLiteral: 'whenTradingCapacityIsDeal',
    },
    {
      isValid: +executorId !== 3 || (+decisorId === 0 && ['MATCH', 'ANY_OTHER'].includes(tc)),
      messageLiteral: 'whenExecutorIdIsThree',
    },
  ]

  return {
    isValid: validations.every(e => e.isValid),
    errorMessageLiterals: validations.filter(e => !e.isValid).map(e => e.messageLiteral),
  }
}
