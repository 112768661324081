/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'

import PriceCell, { sides } from './priceCell'
import QuantityCell from './quantityCell'
import {
  updateQuoteInstrumentData,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
} from '../../actions/workspace'
import { I18nContext } from '../../containers/i18n'
import { subscribeTickSymbol, unsubscribeTickSymbol } from '../../actions/marketData'
import { showErrorDialog } from '../../actions/workspace'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { orkValidate, searchAccount, getTranslationFromKeys } from '../../common/utilities'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import TradingCapacity from './tradingCapacity'
import { ExternalTable, InternalTable, Expander } from '../NativeTable'

function QuoteList({
  //Own
  openFilter = false,
  expandedRows = [],
  selectedInstruments = {},
  quoteInstrumentData = {},
  filters: { selected = false },
  orkData = {},
  //Props
  tabIndex,
  keycloak,
  //State
  instruments,
  instrumentsGroups,
  isConfirmDialogRequired,
  accountGroups,
  currentAccount,
  accounts,
  //Dispatch
  updateQuoteInstrumentData,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}) {
  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)
  const { colorMode, theme } = useThemeUI()

  // Build the default state of component with the actual instrument groups only if expandedRows not contains data
  useEffect(() => {
    if (JSON.stringify(expandedRows) === '{}') {
      const instrumentGroupKeys = Object.keys(instrumentsGroups)
      const initialExpandedRows = instrumentGroupKeys.reduce(
        (acc, value) => ({ ...acc, [value]: false }),
        {}
      )
      const initialSelectedInstruments = instrumentGroupKeys.reduce(
        (acc1, instrumentGroupKey) => ({
          ...acc1,
          [instrumentGroupKey]: instrumentsGroups[instrumentGroupKey].data.reduce(
            (acc2, instrumentKey) => ({ ...acc2, [instrumentKey]: false }),
            {}
          ),
        }),
        {}
      )
      setExpandedRows({ tabIndex: tabIndex, expandedRows: initialExpandedRows })
      setSelectedInstruments({
        tabIndex: tabIndex,
        selectedInstruments: initialSelectedInstruments,
      })
    }
  }, [])

  // Subscriptions

  // Handle subscription on mount
  useEffect(() => {
    const entries = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (selected) {
      entries.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId, true))
    } else {
      entries.forEach(instrumentTypeId => subscribeOnExpanded(instrumentTypeId))
    }
  }, [])

  function instrumentSelected(instrument, instrumentTypeId) {
    function isSelected() {
      return (
        selectedInstruments[instrumentTypeId] &&
        selectedInstruments[instrumentTypeId][`${instrument.exchangeId}:${instrument.symbol}`]
      )
    }
    return selected ? isSelected() : true
  }

  function subscribeOnExpanded(instrumentTypeId = '') {
    instrumentsGroups &&
      instrumentsGroups[instrumentTypeId] &&
      instrumentsGroups[instrumentTypeId].data
        .filter(instrumentId => instrumentSelected(instruments[instrumentId], instrumentTypeId))
        .forEach(instrumentId => {
          subscribe(instrumentId)
        })
  }

  function subscribe(instrumentId) {
    subscribeTickSymbol({
      symbol: instruments[instrumentId].symbol,
      exchangeId: instruments[instrumentId].exchangeId,
    })
  }

  function unsubscribeOnMinimize(instrumentTypeId = '') {
    instrumentsGroups &&
      instrumentsGroups[instrumentTypeId] &&
      instrumentsGroups[instrumentTypeId].data
        .filter(instrumentId => instrumentSelected(instruments[instrumentId], instrumentTypeId))
        .forEach(instrumentId => {
          unsubscribeTickSymbol(instruments[instrumentId].symbol)
        })
  }

  function subscribeOnUncheck(instrumentTypeId = '', onlyChecked = false) {
    instrumentsGroups[instrumentTypeId].data
      .filter(instrumentId => {
        if (selectedInstruments[instrumentTypeId]) {
          if (onlyChecked) {
            return selectedInstruments[instrumentTypeId][instrumentId]
          }
          return !selectedInstruments[instrumentTypeId][instrumentId]
        }
        return false
      })
      .forEach(instrumentId => {
        subscribe(instrumentId)
      })
  }

  function unsubscribeOnCheck(instrumentTypeId = '') {
    instrumentsGroups[instrumentTypeId].data
      .filter(
        instrumentId =>
          selectedInstruments[instrumentTypeId] &&
          !selectedInstruments[instrumentTypeId][instrumentId]
      )
      .forEach(instrumentId => {
        unsubscribeTickSymbol(instruments[instrumentId].symbol)
      })
  }

  // Expanded instruments by marketId, securityType and securitySubType
  const updateExpanded = instrumentTypeId => {
    const newExpanded = { ...expandedRows }
    newExpanded[instrumentTypeId] = !newExpanded[instrumentTypeId]
    setExpandedRows({ tabIndex: tabIndex, expandedRows: newExpanded })
    // Handle subscriptions on expanded elements
    if (newExpanded[instrumentTypeId]) {
      subscribeOnExpanded(instrumentTypeId)
    } else {
      unsubscribeOnMinimize(instrumentTypeId)
    }
  }

  // Selected instruments by marketId, securityType and securitySubType
  const updateSelected = (instrumentTypeId, exchangeId, symbol, checked) => {
    const newSelected = { ...selectedInstruments }

    newSelected[instrumentTypeId] = {
      ...newSelected[instrumentTypeId],
      [`${exchangeId}:${symbol}`]: checked,
    }

    setSelectedInstruments({ tabIndex: tabIndex, selectedInstruments: newSelected })
    // Handle unsubscription when selected filter is on and instrument is unseleted
    if (selected && !checked) {
      unsubscribeTickSymbol(symbol)
    }
  }

  function updateFilters(checked) {
    setFilters({ tabIndex: tabIndex, filters: { selected: checked } })
    // Handle subscriptions on filter change
    const instrumentTypeIdList = Object.entries(expandedRows)
      .filter(instrumentTypeId => instrumentTypeId[1])
      .map(instrumentTypeId => instrumentTypeId[0])
    if (checked) {
      instrumentTypeIdList.forEach(instrumentTypeId => unsubscribeOnCheck(instrumentTypeId))
    } else {
      instrumentTypeIdList.forEach(instrumentTypeId => subscribeOnUncheck(instrumentTypeId))
    }
  }

  // Build array of groups
  const instrumentTypesList = useMemo(() => Object.keys(instrumentsGroups), [instruments])
  // Build array with the translation text for each group
  const instrumentTypesNameList = useMemo(
    () => instrumentTypesList.map(i => getTranslationFromKeys(i.split(':'), t)),
    [instruments]
  )

  function getSubComponentData(instrumentType) {
    return instrumentsGroups[instrumentType].data
      .map(i => {
        if (
          selected &&
          selectedInstruments[instrumentType] &&
          !selectedInstruments[instrumentType][i]
        ) {
          return null
        }
        return {
          ...instruments[i],
          tabIndex: tabIndex,
          key: instruments[i].id,
        }
      })
      .filter(data => data !== null)
  }

  const handlePriceClick = (instrument, value, side) => {
    const quantity = quoteInstrumentData[instrument.id]?.quantity || 1
    make({
      symbol: instrument.symbol,
      exchangeId: instrument.exchangeId,
      side,
      priceData: value,
      quantity: quantity,
      title: t('confirmOrder'),
    })
  }

  const make = payload => {
    const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, payload)
    if (selectedAccount) {
      const isMemberTrader = hasAnyRole(['member_trader'])
      const info = {
        symbol: payload.symbol,
        exchangeId: payload.exchangeId,
        side: payload.side,
        orderType: 'MARKET',
        quantity: payload.quantity,
        tif: 'DAY',
        operationAccount: selectedAccount.id,
      }
      const infoData = isMemberTrader ? { ...info, ...orkData } : { ...info }

      const message = `${t(payload.side).toUpperCase()} ${payload.quantity}
      ${payload.symbol} ${t('MARKET').toUpperCase()} ${t('DAY').toUpperCase()}`
      if (!isMemberTrader || orkValidate({ ...orkData }).isValid) {
        isConfirmDialogRequired
          ? makeOrderWithConfirm(
              infoData,
              { body: message, title: t('confirmOrder'), selectedAccount: selectedAccount },
              keycloak.token
            )
          : makeOrder(infoData, keycloak.token)
      } else {
        handleModal(t('orkError').toUpperCase())
      }
    } else {
      handleModal(t('accountError'))
    }
  }

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  function onQuantityChange(id = '', value = 1) {
    const quoteList = {
      ...quoteInstrumentData,
      [id]: { quantity: value },
    }
    updateQuoteInstrumentData({ quoteInstrumentData: quoteList, tabIndex: tabIndex })
  }

  return (
    <ExternalTable theme={theme} colorMode={colorMode}>
      <PermissionsVisible anyRoles={['member_trader']}>
        <tfoot>
          <tr>
            <th colSpan={5}>
              <div
                sx={{
                  height: '29px',
                  padding: '2px',
                  boxShadow: 'none',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  color: 'text',
                  backgroundColor: 'aux',
                }}
              >
                <TradingCapacity data={orkData} tabIndex={tabIndex} />
              </div>
            </th>
          </tr>
        </tfoot>
      </PermissionsVisible>
      {/* Header */}
      <thead>
        {openFilter ? (
          <tr className="filterRow">
            <th colSpan={5} className="filter">
              <label className="filterCheckBoxLabel">
                <input
                  type="checkbox"
                  id={'selected'}
                  className="filterCheckBox"
                  onChange={e => updateFilters(e.target.checked)}
                  checked={selected}
                />
                <div className="customCheckBox"></div>
                {t('selected')}
              </label>
            </th>
          </tr>
        ) : null}
        <tr>
          <th className="checkBoxHeader"></th>
          <th></th>
          <th>{t('bidPrice')}</th>
          <th>{t('askPrice')}</th>
          <th className="fixed-size">{t('quantity')}</th>
        </tr>
      </thead>
      {/* Body */}
      <tbody>
        {instrumentTypesList.map((instrumentTypeId, index) => {
          return (
            <tr key={instrumentTypeId}>
              <td colSpan={5}>
                <Expander
                  expanded={expandedRows[instrumentTypeId]}
                  onClick={() => updateExpanded(instrumentTypeId)}
                  text={instrumentTypesNameList[index]}
                  theme={theme}
                />
                {/* if instrument group is expanded render instruments */}
                {expandedRows[instrumentTypeId] ? (
                  <InternalTable theme={theme}>
                    <tbody>
                      {getSubComponentData(instrumentTypeId).map(sc => (
                        <tr key={sc.key}>
                          <td className="checkBoxCell">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e =>
                                  updateSelected(
                                    instrumentTypeId,
                                    sc.exchangeId,
                                    sc.symbol,
                                    e.target.checked
                                  )
                                }
                                checked={
                                  (selectedInstruments[instrumentTypeId] &&
                                    selectedInstruments[instrumentTypeId][
                                      `${sc.exchangeId}:${sc.symbol}`
                                    ]) ||
                                  false
                                }
                              />
                              <div className="customCheckBox" />
                            </label>
                          </td>
                          <td>{sc.name}</td>
                          <td>
                            <PriceCell
                              instrument={sc}
                              side={sides.SELL}
                              onPriceClick={(price, side) => handlePriceClick(sc, price, side)}
                            />
                          </td>
                          <td>
                            <PriceCell
                              instrument={sc}
                              side={sides.BUY}
                              onPriceClick={(price, side) => handlePriceClick(sc, price, side)}
                            />
                          </td>
                          <td className="fixed-size">
                            <QuantityCell
                              id={sc.id}
                              onQuantityChange={onQuantityChange}
                              value={quoteInstrumentData[sc.id]?.quantity || 1}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </InternalTable>
                ) : null}
              </td>
            </tr>
          )
        })}
      </tbody>
    </ExternalTable>
  )
}

const mapStateToProps = (
  {
    instruments: { data: instruments, groupBy: instrumentsGroups },
    accounts: { data: accounts },
    workspace: {
      workspaces,
      currentWorkspace,
      isConfirmDialogRequired,
      accountGroups,
      currentAccount,
    },
  },
  ownProps
) => ({
  openFilter: workspaces[currentWorkspace].tabs[ownProps.tabIndex].openFilter,
  expandedRows: workspaces[currentWorkspace].tabs[ownProps.tabIndex].expandedRows,
  selectedInstruments: workspaces[currentWorkspace].tabs[ownProps.tabIndex].selectedInstruments,
  quoteInstrumentData: workspaces[currentWorkspace].tabs[ownProps.tabIndex].quoteInstrumentData,
  filters: workspaces[currentWorkspace].tabs[ownProps.tabIndex].filters,
  orkData: workspaces[currentWorkspace].tabs[ownProps.tabIndex].orkData,

  instruments,
  instrumentsGroups,
  accounts,
  isConfirmDialogRequired,
  accountGroups,
  currentAccount,
})

const mapDispatchToProps = {
  updateQuoteInstrumentData,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  setFilters,
  setExpandedRows,
  setSelectedInstruments,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteList)
