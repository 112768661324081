import {
  always,
  append,
  compose,
  dec,
  equals,
  find,
  findIndex,
  flip,
  gt,
  ifElse,
  inc,
  insert,
  lensPath,
  mergeLeft,
  over,
  propEq,
  propOr,
  reject,
  remove,
  set,
  view,
  when,
  pick,
} from 'ramda'

import { cLens } from '../common/utilities'

const WORKSPACE_VERSION = '1.4.0'

const defaultSplitQuoteTab = {
  name: 'Default Tab',
  type: 'SPLIT_QUOTE',
  spots: [],
  deleting: false,
}
export const defaultLayout = {
  global: { tabEnableRename: false, tabSetEnableMaximize: false, tabSetTabStripHeight: 30 },
  layout: {
    type: 'row',
    children: [
      {
        type: 'tabset',
        children: [],
        weight: 50,
      },
    ],
  },
}

const defaultWorkspace = {
  workspaces: [
    {
      key: 1,
      name: 'New Workspace',
      tabs: [
        {
          type: 'ACCOUNT_SUMMARY_LIST',
          accessor: [
            'account',
            'unrealizedPnLEURValue',
            'realizedProfitEURValue',
            'deferralVariationMarginEURValue',
            'customerAccountMaxMargin',
            'customerAccountTotalMargin',
            'remainingCollateral',
            'operationAccountTotalMargin',
          ],
          filters: [],
          openFilter: false,
          openAccessor: false,
          download: false,
          key: 5,
          name: 'Resumen de la cuenta',
        },
        {
          type: 'POSITION_LIST',
          accessor: [
            'account',
            'symbol',
            'netPositionFormat',
            'netPositionValue',
            'averagePriceFormat',
            'deferralVariationMargin',
            'deferralVariationMarginEURValue',
            'unrealizedPnL',
            'unrealizedPnLEURValue',
            'realizedProfit',
            'realizedProfitEURValue',
          ],
          filters: [],
          openFilter: true,
          openAccessor: false,
          download: false,
          key: 7,
          name: 'Posiciones',
        },
        {
          name: 'Split Quote con profundidad',
          type: 'DEPTH_SPLIT_QUOTE',
          spots: [
            {
              value: 'EURUSD',
              key: 1,
              status: 'VIEW',
              amountToOperate: 1,
              tradingCapacity: 'ANY_OTHER',
              clientId: '',
              decisorId: '',
              executorId: '',
              id: 'BME:MEFF:MD:EURUSD',
            },
            {
              value: 'EURGBP',
              key: 2,
              status: 'VIEW',
              amountToOperate: 1,
              tradingCapacity: 'ANY_OTHER',
              clientId: '',
              decisorId: '',
              executorId: '',
              id: 'BME:MEFF:MD:EURGBP',
            },
          ],
          deleting: false,
          key: 3,
        },
        {
          type: 'ORDER_LIST',
          accessor: [
            'orderStatus',
            'actions',
            'orderId',
            'formatDate',
            'formatAccount',
            'symbol',
            'side',
            'formatQuantity',
            'currency',
            'formatPrice',
            'formatAveragePrice',
            'orderType',
            'tif',
            'tradingCapacity',
            'formatPendingQuantity',
            'text',
            'orderRejectText',
            'formatUpdateDate',
            'clientOrderId',
            'orderCancelText',
            'clientId',
            'decisorId',
            'executorId',
            'stopPrice',
            'formatExpirationDate',
            'execStatus',
            'exchangeId',
          ],
          filters: [],
          openFilter: true,
          openAccessor: false,
          download: false,
          top: 0,
          left: 0,
          key: 11,
          name: 'Órdenes',
        },
        {
          type: 'TRADE',
          value: 1,
          amount: 1,
          orderType: 'LIMIT',
          orderDuration: 'DAY',
          tradingCapacity: 'ANY_OTHER',
          clientId: '',
          decisorId: '',
          executorId: '',
          action: 'BUY',
          key: 16,
          name: 'Trade',
          expirationDate: '2022-06-02T22:00:00.000Z',
          tabIndex: 4,
        },
      ],
      layout: {
        global: {
          tabEnableRename: false,
          tabSetEnableMaximize: false,
          tabSetTabStripHeight: 30,
        },
        layout: {
          type: 'row',
          id: '#1',
          children: [
            {
              type: 'row',
              id: '#13',
              children: [
                {
                  type: 'row',
                  id: '#14',
                  weight: 75,
                  children: [
                    {
                      type: 'tabset',
                      id: '#4',
                      weight: 9.904832169422319,
                      children: [
                        {
                          type: 'tab',
                          id: '#3',
                          name: 'Split Quote con profundidad',
                          component: 'DEPTH_SPLIT_QUOTE',
                        },
                      ],
                    },
                    {
                      type: 'tabset',
                      id: '#17',
                      weight: 5.9757935782308085,
                      children: [
                        {
                          type: 'tab',
                          id: '#16',
                          name: 'Trade',
                          component: 'TRADE',
                        },
                      ],
                      active: true,
                    },
                    {
                      type: 'row',
                      id: '#9',
                      weight: 17.452707585680212,
                      children: [
                        {
                          type: 'tabset',
                          id: '#6',
                          weight: 50,
                          children: [
                            {
                              type: 'tab',
                              id: '#5',
                              name: 'Resumen de la cuenta',
                              component: 'ACCOUNT_SUMMARY_LIST',
                            },
                          ],
                        },
                        {
                          type: 'tabset',
                          id: '#8',
                          weight: 50,
                          children: [
                            {
                              type: 'tab',
                              id: '#7',
                              name: 'Posiciones',
                              component: 'POSITION_LIST',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'tabset',
                  id: '#12',
                  weight: 25,
                  children: [
                    {
                      type: 'tab',
                      id: '#11',
                      name: 'Órdenes',
                      component: 'ORDER_LIST',
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      activeTab: 0,
    },
  ],
  currentWorkspace: 0,
  isConfirmDialogOpen: false,
  isConfirmDialogRequired: true,
  isSettingsOpen: false,
  modal: {
    open: false,
    title: null,
    message: null,
    action: {},
    side: null,
    selectedAccount: null,
    orderInfo: null,
  },
  modalMessage: {
    open: false,
    type: 'ERROR',
    title: null,
    message: null,
  },
  fetching: false,
  fetched: true,
  error: 404,
  theme: 'dark',
  currentAccount: null,
  accountGroups: [],
  language: 'es',
  key: 0,
  name: 'New Workspace',
  tabs: [],
  layout: {
    global: {
      tabEnableRename: false,
      tabSetEnableMaximize: false,
      tabSetTabStripHeight: 30,
    },
    layout: {
      type: 'row',
      children: [
        {
          type: 'tabset',
          children: [],
          weight: 50,
        },
      ],
    },
  },
  activeTab: 0,
  version: WORKSPACE_VERSION,
}

const newWorkspace = {
  key: 0,
  name: 'New Workspace',
  tabs: [],
  layout: defaultLayout,
  activeTab: 0,
}
const defaultModal = {
  open: false,
  title: '',
  message: '',
  action: {},
  side: '',
  selectedAccount: '',
  orderInfo: {},
}
const defaultModalMessage = {
  open: false,
  type: 'ERROR',
  title: '',
  message: '',
}
const defaultTable = { name: 'Default table', type: 'TABLE' }
const defaultRfq = { type: 'RFQ' }
const defaultRfqData = {
  pair: 'EUR/USD',
  tenor: '',
  amount: '',
  currency: '',
  side: '',
  oid: '',
}

const defaultAccountGroup = {
  id: 1,
  name: 'Account Groups Name',
  accounts: [],
}

const defaultState = {
  workspaces: [newWorkspace],
  currentWorkspace: 0,
  isConfirmDialogOpen: false,
  isConfirmDialogRequired: true,
  isSettingsOpen: false,
  modal: defaultModal,
  modalMessage: defaultModalMessage,
  fetching: false,
  fetched: false,
  error: null,
  theme: 'dark',
  currentAccount: null,
  accountGroups: [],
  language: 'en',
}
const defaultCurrency = {
  value: '---/---',
  key: 0,
  status: 'VIEW',
  amountToOperate: 1,
  tradingCapacity: 'ANY_OTHER',
  clientId: '',
  decisorId: '',
  executorId: '',
}
const defaultTradePanel = {
  type: 'TRADE',
  value: 1,
  amount: 1,
  orderType: 'LIMIT',
  orderDuration: 'DAY',
  tradingCapacity: 'ANY_OTHER',
  clientId: '',
  decisorId: '',
  executorId: '',
  action: 'BUY',
}
const defaultOrderListPanel = {
  type: 'ORDER_LIST',
  accessor: [],
  filters: [],
  openFilter: false,
  openAccessor: false,
  download: false,
  top: 0,
  left: 0,
}
const defaultTradeListPanel = {
  type: 'TRADE_LIST',
  accessor: [],
  filters: [],
  openFilter: false,
  openAccessor: false,
  download: false,
}

const defaultPriceListPanel = {
  type: 'PRICE_LIST',
  filters: { selected: false },
  openFilter: false,
  expandedRows: {},
  selectedInstruments: {},
}

const defaultQuoteListPanel = {
  type: 'QUOTE_LIST',
  filters: { selected: false },
  openFilter: false,
  expandedRows: {},
  selectedInstruments: {},
  quoteInstrumentData: {},
}

const defaultPositionListPanel = {
  type: 'POSITION_LIST',
  accessor: [],
  filters: [],
  openFilter: false,
  openAccessor: false,
  download: false,
}

const defaultMarketDepthPanel = {
  type: 'MARKET_DEPTH',
}

const defaultAccountGroupPanel = {
  type: 'ACCOUNT_GROUP',
  accessor: [],
  filters: [],
  openFilter: false,
  openAccessor: false,
  download: false,
}
const defaultAccountSummaryListPanel = {
  type: 'ACCOUNT_SUMMARY_LIST',
  accessor: [],
  filters: [],
  openFilter: false,
  openAccessor: false,
  download: false,
}

const toggleView = ifElse(equals('EDIT'), always('VIEW'), always('EDIT'))

export default (state = defaultState, { type, payload } = {}) => {
  // Lenses
  const workLense = lensPath(['workspaces'])
  const currLense = lensPath(['currentWorkspace'])
  const tabsLense = lensPath(['workspaces', state.currentWorkspace, 'tabs'])
  const tabsOrkLense = lensPath(['workspaces', state.currentWorkspace, 'tabs'])
  const accountGroupsLense = lensPath(['accountGroups'])

  switch (type) {
    case 'FETCH_WORKSPACE_PENDING':
      return { ...state, fetching: true, fetched: false, error: null }
    case 'FETCH_WORKSPACE_REJECTED':
      return { ...state, ...defaultWorkspace, fetching: false, fetched: true, error: payload }
    case 'FETCH_WORKSPACE_FULFILLED':
      if (!payload.version) {
        return { ...state, ...defaultWorkspace, fetching: false, fetched: true, error: null }
      }
      return { ...state, ...payload, fetching: false, fetched: true, error: null }
    case 'SAVE_WORKSPACE_PENDING':
    case 'SAVE_WORKSPACE_REJECTED':
    case 'SAVE_WORKSPACE_FULFILLED':
      return state
    case 'CHANGE_WORKSPACE':
      return { ...state, currentWorkspace: payload }
    case 'ADD_WORKSPACE':
      return over(workLense, append({ ...newWorkspace, key: state.workspaces.length }), state)
    case 'REMOVE_WORKSPACE':
      const decIf = when(flip(gt)(0), dec)
      return over(workLense, remove(payload, 1), over(currLense, decIf, state))
    case 'ADD_CURRENCY_TAB':
      return over(
        tabsLense,
        append({
          ...defaultSplitQuoteTab,
          key: payload.key,
          name: payload.name,
          type: payload.typeTab,
        }),
        state
      )
    case 'ADD_TABLE_TAB':
      return over(
        tabsLense,
        append({ ...defaultTable, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_RFQ_TAB':
      return over(
        tabsLense,
        append({
          ...defaultRfq,
          key: payload.key,
          rfqId: payload.index,
          name: payload.name,
          data: { ...defaultRfqData, ...{ oid: payload.oid } },
        }),
        state
      )
    case 'ADD_TRADE_PANEL':
      return over(
        tabsLense,
        append({ ...defaultTradePanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_ORDER_LIST_PANEL':
      return over(
        tabsLense,
        append({ ...defaultOrderListPanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_TRADE_LIST_PANEL':
      return over(
        tabsLense,
        append({ ...defaultTradeListPanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_QUOTE_LIST_PANEL':
      return over(
        tabsLense,
        append({ ...defaultQuoteListPanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_PRICE_LIST_PANEL':
      return over(
        tabsLense,
        append({ ...defaultPriceListPanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_POSITION_LIST_PANEL':
      const { key, name, typeTab } = payload
      return over(
        tabsLense,
        append({
          ...defaultPositionListPanel,
          key: key,
          name,
          type: typeTab,
        }),
        state
      )
    case 'ADD_MARKET_DEPTH_PANEL':
      return over(
        tabsLense,
        append({ ...defaultMarketDepthPanel, key: payload.key, name: payload.name }),
        state
      )
    case 'ADD_ACCOUNT_SUMMARY_LIST_PANEL':
      return over(
        tabsLense,
        append({
          ...defaultAccountSummaryListPanel,
          key: payload.key,
          acc: payload.account,
          name: payload.name,
        }),
        state
      )
    case 'ADD_ACCOUNT_GROUP_PANEL':
      return over(
        tabsLense,
        append({
          ...defaultAccountGroupPanel,
          key: payload.key,
          name: payload.name,
        }),
        state
      )
    case 'ADD_ACCOUNT_GROUP':
      //The new id is a unit more than the maximum value of the existing keys, or 1 if there is none
      const id = inc(
        Math.max(...view(accountGroupsLense, state).map(e => (isNaN(e.id) ? 0 : e.id)), 0)
      )
      return over(
        accountGroupsLense,
        append({
          ...defaultAccountGroup,
          ...payload,
          ...(!payload.id && { id: id }),
        }),
        state
      )
    case 'UPDATE_ACCOUNT_GROUP':
      const index = findIndex(propEq('id', payload.id), state.accountGroups)
      const accGroupIdxLense = cLens(accountGroupsLense, index)
      return set(accGroupIdxLense, payload, state)
    case 'DELETE_ACCOUNT_GROUP':
      return over(accountGroupsLense, reject(propEq('id', payload.id)), state)
    case 'CHANGE_CURRENT_ACCOUNT':
      //get payload id or last id in case of new account group
      const accountId = payload.id || Math.max(0, ...state.accountGroups.map(b => b.id))
      //get last group or null values object
      const accountGroupById =
        !payload.id &&
        pick(
          ['id', 'name', 'type'],
          find(propEq('id', accountId), state.accountGroups) || { name: null, type: null }
        )
      return {
        ...state,
        currentAccount: { ...state.currentAccount, ...accountGroupById, ...payload },
      }
    case 'REMOVE_TAB':
      return over(tabsLense, reject(propEq('key', payload.index)), state)
    case 'UPDATE_WORKSPACE_NAME':
      const workIdxLense = cLens(workLense, payload.index, 'name')
      return set(workIdxLense, payload.name, state)
    case 'ADD_CURRENCY':
      const spotsLense = cLens(tabsLense, payload.tabIndex, 'spots')
      return over(
        spotsLense,
        append({
          ...defaultCurrency,
          value: payload.instrument.symbol,
          id: `${payload.instrument.exchangeId}:${payload.instrument.id}`,
          //The new key is a unit more than the maximum value of the existing keys, or 1 if there is none
          key: inc(Math.max(...view(spotsLense, state).map(e => (isNaN(e.key) ? 0 : e.key)), 0)),
        }),
        state
      )
    case 'DELETE_CURRENCY':
      const spotsLense2 = cLens(tabsLense, payload.tabIndex, 'spots')
      return over(spotsLense2, remove(payload.spotIndex, 1), state)
    case 'RESET_CURRENCY':
      const spotsLense3 = cLens(tabsLense, payload.tabIndex, 'deleting')
      return set(spotsLense3, payload.deleting, state)
    case 'SET_TABLE_FILTERS':
      const filtersLens = cLens(tabsLense, payload.tabIndex, 'filters')
      return set(filtersLens, payload.filters, state)
    case 'SET_TABLE_ACCESSORS':
      const accLens = cLens(tabsLense, payload.tabIndex, 'accessor')
      return set(accLens, payload.accessor, state)
    case 'SET_OPENFILTER':
      const openFilterLens = cLens(tabsLense, payload.tabIndex, 'openFilter')
      return set(openFilterLens, payload.openFilter, state)
    case 'SET_OPENACCESSOR':
      const tabLens = cLens(tabsLense, payload.tabIndex)
      return over(tabLens, mergeLeft(payload), state)
    case 'SHOW_CONFIRM_DIALOG':
      return {
        ...state,
        modal: { ...state.modal, ...payload, open: true },
      }
    case 'CLOSE_CONFIRM_DIALOG':
      return { ...state, modal: { ...defaultModal } }
    case 'SHOW_ERROR_DIALOG':
      return {
        ...state,
        modalMessage: { ...state.modalMessage, ...payload, open: true },
      }
    case 'CLOSE_ERROR_DIALOG':
      return { ...state, modalMessage: { ...defaultModal } }
    case 'CHANGE_TO_VIEW_MODE':
      const spotsIdxLense = cLens(tabsLense, payload.tabIndex, 'spots', payload.spotIndex)
      const spotValue = cLens(spotsIdxLense, 'value')
      const spotStatus = cLens(spotsIdxLense, 'status')
      const valChanged = set(
        spotValue,
        propOr(view(spotValue, state), 'symbol', payload.instrument),
        state
      )
      return over(spotStatus, toggleView, valChanged)
    case 'EDIT_TRADE_PANEL':
      const tabStatusLens = cLens(tabsLense, payload, 'status')
      return over(tabStatusLens, toggleView, state)
    case 'UPDATE_AMOUNT_TO_OPERATE':
      const spotAmountLense = cLens(
        tabsLense,
        payload.tabIndex,
        'spots',
        payload.index,
        'amountToOperate'
      )
      return set(spotAmountLense, payload.value, state)
    case 'UPDATE_TRADE_PANEL':
      const amountLens = cLens(tabsLense, payload.tabIndex)
      return over(amountLens, mergeLeft(payload), state)
    case 'UPDATE_ORK_DATA':
      const { tabIndex, ...data } = payload
      const orkDataLens = cLens(tabsOrkLense, tabIndex, 'orkData')
      return over(orkDataLens, mergeLeft(data), state)
    case 'UPDATE_QUOTE_LIST':
      const quoteLens = cLens(tabsLense, payload.tabIndex)
      return over(quoteLens, mergeLeft(payload), state)
    case 'UPDATE_PRICE_LIST':
      const priceLens = cLens(tabsLense, payload.tabIndex)
      return over(priceLens, mergeLeft(payload), state)
    case 'CREATE_LAYOUT':
      const layoutLense = cLens(workLense, payload.workspaceIndex, 'layout')
      return set(layoutLense, payload.layout, state)
    case 'REORDER_CURRENCIES':
      const { newIndex, oldIndex, currentTab } = payload
      const spotsLens = cLens(tabsLense, currentTab, 'spots')
      const spotsOldIdxLense = cLens(spotsLens, oldIndex)
      const updateOld = over(spotsLens, remove(oldIndex, 1))
      const updateNew = over(spotsLens, insert(newIndex, view(spotsOldIdxLense, state)))
      return compose(updateNew, updateOld)(state)
    case 'UPDATE_CURRENT_LAYOUT':
      const layoutLense2 = cLens(workLense, payload.workspaceIndex, 'layout', 'layout')
      return set(layoutLense2, payload.layout, state)
    case 'CHANGE_TRADING_CAPACITY':
      const tradingLens = cLens(
        tabsLense,
        payload.tabIndex,
        'spots',
        payload.spotIndex,
        'tradingCapacity'
      )
      return set(tradingLens, payload.tradingCapacity, state)
    case 'CHANGE_TRADING_ORK':
      const tradingSpotLens = cLens(tabsLense, payload.tabIndex, 'spots', payload.spotIndex)
      return over(tradingSpotLens, mergeLeft(payload), state)
    case 'CHANGE_CONFIRM_MODAL_REQUIRED':
      return { ...state, isConfirmDialogRequired: !state.isConfirmDialogRequired }
    case 'SHOW_USER_SETTINGS':
      return { ...state, isSettingsOpen: true }
    case 'HIDE_USER_SETTINGS':
      return { ...state, isSettingsOpen: false }
    case 'SAVE_OID':
      const oidLens = cLens(tabsLense, payload.index, 'oid')
      return set(oidLens, payload.oid, state)
    case 'GET_RFQ':
      const tabIdxLense = cLens(tabsLense, payload.tabIndex)
      const dataLens = cLens(tabIdxLense, 'data')
      return over(
        dataLens,
        when(propEq('rfqId', payload.data.id, view(tabIdxLense, state)), mergeLeft(payload.data)),
        state
      )
    case 'SAVE_ACTIVE_TAB':
      const workActLense = cLens(workLense, payload.index, 'activeTab')
      return set(workActLense, payload.activeTab, state)
    case 'SET_LANGUAGE':
      return { ...state, language: payload.language }
    case 'SET_EXPANDED_ROWS':
      const expandedRowsLens = cLens(tabsLense, payload.tabIndex, 'expandedRows')
      return set(expandedRowsLens, payload.expandedRows, state)
    case 'SET_SELECTED_INSTRUMENTS':
      const selectedRowsLens = cLens(tabsLense, payload.tabIndex, 'selectedInstruments')
      return set(selectedRowsLens, payload.selectedInstruments, state)
    case 'SET_QUOTE_INSTRUMENT_DATA':
      const quoteInstrumentData = cLens(tabsLense, payload.tabIndex, 'quoteInstrumentData')
      return set(quoteInstrumentData, payload.quoteInstrumentData, state)
    default:
      return state
  }
}
