/** @jsx jsx */
import React, { memo, useContext } from 'react'
import { jsx } from 'theme-ui'
import * as style from './style'
import { connect } from 'react-redux'
import { I18nContext } from '../../containers/i18n'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { orkValidate, searchAccount } from '../../common/utilities'
import { showErrorDialog } from '../../actions/workspace'
import { shortenNumber } from '../../common/utilities/formatNumber'

const dataPlaceHolder = '-'

const getMake = ({
  payload,
  currentAccount,
  accountGroups,
  accounts,
  instrument,
  workspaces,
  currentWorkspace,
  tabIndex,
  spotIndex,
  hasAnyRole,
  t,
  isConfirmDialogRequired,
  makeOrderWithConfirm,
  makeOrder,
  keycloak,
  handleModal,
}) => {
  const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)
  if (selectedAccount) {
    const { amountToOperate, clientId, decisorId, executorId, tradingCapacity } = workspaces[
      currentWorkspace
    ].tabs[tabIndex].spots[spotIndex]
    const isMemberTrader = hasAnyRole(['member_trader'])
    const trading = {
      clientId: clientId,
      decisorId: decisorId,
      executorId: executorId,
      tradingCapacity: tradingCapacity,
    }
    const info = {
      price: payload.priceData,
      symbol: payload.currency,
      exchangeId: payload.exchangeId,
      side: payload.direction,
      orderType: 'LIMIT',
      quantity: amountToOperate,
      tif: 'DAY',
      operationAccount: selectedAccount.id,
    }
    const infoData = isMemberTrader ? { ...info, ...trading } : { ...info }
    const message = `${t(payload.action).toUpperCase()} ${amountToOperate}
      ${payload.currency} @ ${payload.priceData} ${t('LIMIT').toUpperCase()} ${t(
      'DAY'
    ).toUpperCase()}`
    if (!isMemberTrader || orkValidate({ ...trading }).isValid) {
      isConfirmDialogRequired
        ? makeOrderWithConfirm(
            infoData,
            { body: message, title: t('confirmOrder'), selectedAccount: selectedAccount },
            keycloak.token
          )
        : makeOrder(infoData, keycloak.token)
    } else {
      handleModal(t('orkError').toUpperCase())
    }
  } else {
    handleModal(t('accountError'))
  }
}

const MarketDepthPanel = ({
  instrument,
  instrumentDepthData,
  depth,
  currency,
  spotIndex,
  tabIndex,
  keycloak,
  isConfirmDialogRequired,
  workspaces,
  currentWorkspace,
  accountGroups,
  currentAccount,
  accounts,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}) => {
  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const make = payload => {
    getMake({
      payload,
      currentAccount,
      accountGroups,
      accounts,
      instrument,
      workspaces,
      currentWorkspace,
      tabIndex,
      spotIndex,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  function makeSell(bid, currency, instrument) {
    bid && makeTrade(currency, 'SELL', 'SELLACTION', bid, instrument.exchangeId)
  }

  function makeBuy(ask, currency, instrument) {
    ask && makeTrade(currency, 'BUY', 'BUYACTION', ask, instrument.exchangeId)
  }

  function makeTrade(currency, direction, action, priceData, exchangeId) {
    make({
      currency,
      direction,
      action,
      priceData,
      exchangeId,
      title: t('confirmOrder'),
    })
  }

  const buildDepth = () => {
    const marketDepth = []
    const depthPositions = depth + 1
    for (var i = 1; i < depthPositions; i++) {
      const instrumentData = instrumentDepthData[i] || undefined
      const bid = instrumentData && instrumentData.bidPrice !== '0' && instrumentData.bidPrice
      const ask = instrumentData && instrumentData.askPrice !== '0' && instrumentData.askPrice
      marketDepth.push(
        <div key={`${instrument.name}${i}`} sx={style.depthSection}>
          <div sx={style.setBoxLeftStyle}>
            {instrumentData && instrumentData.bidVolume !== '0' && instrumentData.bidVolume
              ? shortenNumber(instrumentData.bidVolume, 1)
              : dataPlaceHolder}
          </div>
          <div
            sx={style.setButtonStyle()}
            onClick={() => {
              makeSell(bid, currency, instrument)
            }}
          >
            {bid || dataPlaceHolder}
          </div>
          <div
            sx={style.setButtonStyle(true)}
            onClick={() => {
              makeBuy(ask, currency, instrument)
            }}
          >
            {ask || dataPlaceHolder}
          </div>
          <div sx={style.setBoxRightStyle}>
            {instrumentData && instrumentData.askVolume !== '0' && instrumentData.askVolume
              ? shortenNumber(instrumentData.askVolume, 1)
              : dataPlaceHolder}
          </div>
        </div>
      )
    }
    return marketDepth
  }

  return (
    <div className="container" sx={style.depthPanel}>
      <div sx={style.depthHeader}>
        <div sx={style.setLabelVolLeftStyle}>{t('vol')}</div>
        <div sx={style.setLabelPriceStyle}>{t('bid')}</div>
        <div sx={style.setLabelPriceStyle}>{t('ask')}</div>
        <div sx={style.setLabelVolRightStyle}>{t('vol')}</div>
      </div>
      {buildDepth()}
    </div>
  )
}

const mapStateToProps = ({
  workspace: {
    workspaces,
    currentWorkspace,
    isConfirmDialogRequired,
    accountGroups,
    currentAccount,
  },
  accounts: { data: accounts },
}) => ({
  isConfirmDialogRequired,
  workspaces,
  currentWorkspace,
  accountGroups,
  currentAccount,
  accounts,
})
const mapDispatchToProps = {
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(MarketDepthPanel))
