/** @jsx jsx */
import { jsx } from 'theme-ui'
import { memo } from 'react'

import { renderPips } from '../../common/utilities'

const movement = {
  bg: 'muted',
  borderRadius: '5px',
  fontSize: 0,
  height: '18px',
  left: '42%',
  mt: '51%',
  pt: '1px',
  position: 'absolute',
  textAlign: 'center',
  width: '35px',
}

const PriceMovement = ({ bid, ask, instrument }) => {
  const priceBid = renderPips(bid, instrument)
  const priceAsk = renderPips(ask, instrument)
  const valueBid = priceBid.bigFigure + priceBid.pips + priceBid.fractionalPips
  const valueAsk = priceAsk.bigFigure + priceAsk.pips + priceAsk.fractionalPips
  return (
    <div sx={movement}>
      {priceBid.fractionalPips && priceAsk.fractionalPips
        ? ((valueAsk - valueBid) * 10 ** instrument.pipDecimal).toFixed(1)
        : 0}
    </div>
  )
}

export default memo(PriceMovement)
