import React from 'react'
import { map, uncurryN } from 'ramda'
import { Checkbox, ListItemText } from '@material-ui/core'
import { MenuItem } from '../utilities'

const statusSortOrder = [
  'PENDING_NEW',
  'REJECTED',
  'NEW',
  'PENDING_REPLACE',
  'PENDING_CANCEL',
  'PARTIALLY_FILLED',
  'FILLED',
  'CANCELLED',
]

export const getOptions = uncurryN(2, t =>
  map(key => (
    <MenuItem key={key} value={key} classes={{ selected: 'customSelected' }}>
      {t(key)}
    </MenuItem>
  ))
)
export const getColumns = uncurryN(3, (accessor, style) =>
  map(c => (
    <MenuItem key={c.accessor} value={c.accessor} className={style.menuItem}>
      <Checkbox checked={accessor.includes(c.accessor)} />
      <ListItemText primary={c.Header.props.children} />
    </MenuItem>
  ))
)
export const sortStatus = (a, b) => statusSortOrder.indexOf(a) - statusSortOrder.indexOf(b)
export const sortDate = (a, b) => (new Date(a).getTime() || 0) - (new Date(b).getTime() || 0)

export const sortCurrencyNumber = (a, b) => {
  // Basic null check
  if (a == undefined) return 1
  if (b == undefined) return -1

  // Convert to string
  a = '' + a
  b = '' + b

  // Find the last appearance of a space, to remove the currency
  let lastSpaceA = a.lastIndexOf(' ')
  if (lastSpaceA === -1) {
    lastSpaceA = a.length
  }
  let lastSpaceB = ('' + b).lastIndexOf(' ')
  if (lastSpaceB === -1) {
    lastSpaceB = b.length
  }

  // Remove the currency and added thousands space separators
  a = parseFloat(a.slice(0, lastSpaceA).replace(' ', ''))
  b = parseFloat(b.slice(0, lastSpaceB).replace(' ', ''))
  return b > a ? 1 : -1
}

export const sortNumber = (a, b) => {
  return b > a ? 1 : -1
}
