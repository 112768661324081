/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext, useState, useMemo } from 'react'

import { fmtDateLongFromyyyMMdd, fmtDateLongMili } from '../../../common/utilities/formatDate'
import { I18nContext } from '../../../containers/i18n'
import TableAdmin from '../../TableAdmin'
import { columnsHead, getDefaultFilters, defaultSorted } from './columnsHead'
import { isEmpty } from 'ramda'

const getDifference = (a, b) =>
  b &&
  Object.entries(b)
    .filter(([key, val]) => a[key] !== val && key in a)
    .reduce((a, [key, v]) => ({ ...a, [key]: v }), {})

const FilterTable = ({ data = [] }) => {
  const { t } = useContext(I18nContext)
  const tabIndex = 'orderDetails'
  const [filtersTable, setFiltersTable] = useState(getDefaultFilters())

  const memoizedTableData = useMemo(
    () =>
      isEmpty(data)
        ? []
        : data
            .map(o => ({
              //...o,
              formatDate: fmtDateLongMili(o.updateDate),
              orderStatus: `${o.orderStatus}`,
              execStatus: `${o.execStatus}`,
              clientOrderId: `${o.clientOrderId}`,
              marketOrderId: o.orderId && o.orderId !== 'NONE' ? o.orderId : '',
              trader: o.userId,
              formatQuantity: +o.quantity || '',
              limitPrice: `${o.price || ''}`,
              stopPrice: `${o.stopPrice || ''}`,
              executionPrice: `${o.lastPrice || ''}`,
              formatPendingQuantity:
                (o.pendingQuantity !== null || o.pendingQuantity !== undefined) &&
                !isNaN(o.pendingQuantity)
                  ? +o.pendingQuantity
                  : '',
              reason: `${o.orderRejectReason || ''}\n${o.orderCancelReason || ''}`,
              ork:
                o.tradingCapacity && o.clientId && o.decisorId && o.executorId
                  ? `${o.tradingCapacity}:${o.clientId},${o.decisorId},${o.executorId}`
                  : '',
              text: `${o.orderRejectText || ''}\n${o.orderCancelText || ''}\n${o.text || ''}`,
              formatExpirationDate: fmtDateLongFromyyyMMdd(o.expirationDate),
            }))
            //Sort by date
            .sort(
              (a, b) =>
                (new Date(b.formatDate).getTime() || 0) - (new Date(a.formatDate).getTime() || 0)
            ),
    // .map((o, index, array) => {
    //   const difference = getDifference(o, array[index - 1])
    //   return {
    //     ...o,
    //     difference: index > 0 && difference && Object.keys(difference),
    //   }
    // })
    [data]
  )

  return (
    <TableAdmin
      tableTitle={t('orderHistory')}
      columns={columnsHead(t)}
      columnsFilter={filtersTable.columns}
      onColumnsFilter={columns => setFiltersTable({ ...filtersTable, columns })}
      filterColumns={true}
      rows={memoizedTableData}
      filterable={false}
      defaultFiltered={filtersTable.rows}
      onChangeDefaultFiltered={rows => setFiltersTable({ ...filtersTable, rows })}
      defaultSorted={defaultSorted}
      styleTable={{ maxHeight: 'calc(100vh - 500px)' }}
      tabIndex={tabIndex}
    />
  )
}

export default FilterTable
