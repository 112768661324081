import Stomp from '@stomp/stompjs'
import SockJS from 'sockjs-client'

import config from '../common/config'

const client = Stomp.over(() => new SockJS(config().urls.marketData))
if (process.env.NODE_ENV !== 'development') {
  client.debug = () => {}
}

const symbols = new Map()
const tradSym = new Map()
const book = new Map()

export const connectToMarketSocket = payload => dispatch =>
  client.connect({ Authorization: `Bearer ${payload}` }, () =>
    dispatch({ type: 'MARKET_SOCKET_CONNECTED' })
  )

export const subscribeTickSymbol = payload => dispatch =>
  symbols.has(payload.symbol)
    ? symbols.set(payload.symbol, {
        ...symbols.get(payload.symbol),
        count: symbols.get(payload.symbol).count + 1,
      })
    : symbols.set(payload.symbol, {
        count: 1,
        sub: client.subscribe(`/public/price/tick/${payload.exchangeId}/${payload.symbol}`, data =>
          dispatch({ type: 'UPDATE_MARKET_TICK_DATA', payload: JSON.parse(data.body) })
        ),
      })

export const unsubscribeTickSymbol = payload => dispatch => {
  if (symbols.get(payload)?.count === 1) {
    symbols.get(payload).sub.unsubscribe()
    dispatch({ type: 'REMOVE_MARKET_TICK_DATA', payload })
    symbols.delete(payload)
  } else if (symbols.has(payload)) {
    symbols.set(payload, { ...symbols.get(payload), count: symbols.get(payload).count - 1 })
  }
}

export const subscribeTradeSymbol = payload => dispatch => {
  if (payload.symbol) {
    tradSym.has(payload.symbol)
      ? tradSym.set(payload.symbol, {
          ...tradSym.get(payload.symbol),
          count: tradSym.get(payload.symbol).count + 1,
        })
      : tradSym.set(payload.symbol, {
          count: 1,
          sub: client.subscribe(
            `/public/price/trade/${payload.exchangeId}/${payload.symbol}`,
            data => dispatch({ type: 'UPDATE_MARKET_TRADE_DATA', payload: JSON.parse(data.body) })
          ),
        })
  }
}

export const unsubscribeTradeSymbol = payload => dispatch => {
  if (tradSym.get(payload)?.count === 1) {
    tradSym.get(payload).sub.unsubscribe()
    dispatch({ type: 'REMOVE_MARKET_TRADE_DATA', payload })
    tradSym.delete(payload)
  } else if (tradSym.has(payload)) {
    tradSym.set(payload, { ...tradSym.get(payload), count: tradSym.get(payload).count - 1 })
  }
}

export const subscribeDepthSymbol = payload => dispatch => {
  if (payload.symbol) {
    book.has(payload.symbol)
      ? book.set(payload.symbol, {
          ...book.get(payload.symbol),
          count: book.get(payload.symbol).count + 1,
        })
      : book.set(payload.symbol, {
          count: 1,
          sub: client.subscribe(
            `/public/price/book/${payload.exchangeId}/${payload.symbol}`,
            data => dispatch({ type: 'UPDATE_MARKET_BOOK_DATA', payload: JSON.parse(data.body) })
          ),
        })
  }
}

export const unsubscribeDepthSymbol = payload => dispatch => {
  if (book.get(payload)?.count === 1) {
    book.get(payload).sub.unsubscribe()
    dispatch({ type: 'REMOVE_MARKET_BOOK_DATA', payload })
    book.delete(payload)
  } else if (book.has(payload)) {
    book.set(payload, { ...book.get(payload), count: book.get(payload).count - 1 })
  }
}
