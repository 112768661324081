/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useContext, useState } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { InputBase, Typography } from '@material-ui/core'
import { allowOnlyNumbers } from '../../common/utilities'
import { updateTradePanel } from '../../actions/workspace'

const Ork = ({ type, tabIndex, value, updateTradePanel }) => {
  const { t } = useContext(I18nContext)
  const [inputValue, setInputValue] = useState(value ? value : '')

  return (
    <>
      <Typography sx={{ ...style.orkTipography }}>{t(type)}</Typography>
      <InputBase
        inputProps={{
          maxLength: '10',
        }}
        sx={{
          ...style.input,
          ...style.inputBase,
        }}
        onBlur={e => updateTradePanel({ [type]: e.target.value, tabIndex })}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue ? inputValue : ''}
        onPaste={allowOnlyNumbers}
        onKeyPress={allowOnlyNumbers}
      />
    </>
  )
}

const mapDispatchToProps = {
  updateTradePanel,
}

export default connect(null, mapDispatchToProps)(Ork)
