/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as style from './style'

import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import 'react-table/react-table.css'

import { setAccessor, setOpenAccessor } from '../../actions/workspace'
import { Table, PanelTableMenu } from '../Table'
import { useColumns } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'
import { getTableId } from '../TableAdmin/TableUtils'

const PanelTable = ({
  rows,
  getColumns,
  tabIndex,
  defaultFiltered,
  defaultSorted,
  children,
  //mapStateToProps
  workspaces,
  currentWorkspace,
  //mapDispatchToProps
  setOpenAccessor,
  setAccessor,
  //rest
  ...props
}) => {
  const { t } = useContext(I18nContext)

  const { openFilter, openAccessor, top, left, accessor = [], filters = [] } = workspaces[
    currentWorkspace
  ].tabs[tabIndex]

  const [
    columnsAux,
    handleChange,
    handleOpen,
    handleClose,
    setColumns,
    columns,
    filterable,
    open,
  ] = useColumns({ accessor, setAccessor, tabIndex, openFilter, openAccessor })

  useEffect(() => {
    setColumns(getColumns())
  }, [t])

  return (
    <div sx={style.container} id={getTableId(tabIndex)}>
      <PanelTableMenu
        {...{
          open,
          handleOpen,
          handleClose,
          handleChange,
          setOpenAccessor,
          tabIndex,
          columns,
          accessor,
          top,
          left,
        }}
      />
      <Table
        columns={columnsAux}
        filterable={filterable}
        filtered={filters}
        rows={rows}
        tabIndex={tabIndex}
        defaultFiltered={defaultFiltered || filters}
        defaultSorted={defaultSorted}
        {...props}
      />
      {children}
    </div>
  )
}

const mapStateToProps = ({ workspace: { workspaces, currentWorkspace } }) => ({
  workspaces,
  currentWorkspace,
})
const mapDispatchToProps = {
  setAccessor,
  setOpenAccessor,
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelTable)
