import { isEmpty } from 'ramda'

export const findInstrument = (symbol, tick) => tick[symbol]

export const getEurExchangeRate = (quote, tick) => {
  if (quote === 'USD') {
    const eurUsd = findInstrument('EURUSD', tick)
    if (eurUsd) {
      const bidPlusAskEurUsd = +eurUsd.askPrice + +eurUsd.bidPrice
      return bidPlusAskEurUsd ? 1 / (bidPlusAskEurUsd / 2) : 0
    }
  }
  if (quote !== 'EUR' && tick && !isEmpty(tick)) {
    const tradeEur = findInstrument(`EUR${quote}`, tick)
    if (tradeEur) {
      const bidPlusAskTradeEur = +tradeEur.askPrice + +tradeEur.bidPrice
      return bidPlusAskTradeEur ? 1 / (bidPlusAskTradeEur / 2) : 0
    }

    const tradeEurUsd = findInstrument('EURUSD', tick)
    if (!tradeEurUsd) return 0
    const bidPlusAskTradeEurUsd = +tradeEurUsd.askPrice + +tradeEurUsd.bidPrice

    const tradeQuoteUsDollar = quote !== 'USD' && findInstrument(`${quote}USD`, tick)
    if (tradeQuoteUsDollar && tradeEurUsd) {
      const bidPlusAskTradeQuote = +tradeQuoteUsDollar.askPrice + +tradeQuoteUsDollar.bidPrice
      return bidPlusAskTradeQuote && bidPlusAskTradeEurUsd
        ? (bidPlusAskTradeQuote / 2) * (1 / (bidPlusAskTradeEurUsd / 2))
        : 0
    }

    const tradeUsDollarQuote = quote !== 'USD' && findInstrument(`USD${quote}`, tick)
    if (tradeUsDollarQuote && tradeEurUsd) {
      const bidPlusAskTradeUsDollarQuote =
        +tradeUsDollarQuote.askPrice + +tradeUsDollarQuote.bidPrice || 0
      return bidPlusAskTradeUsDollarQuote && bidPlusAskTradeEurUsd
        ? (1 / (bidPlusAskTradeUsDollarQuote / 2)) * (1 / (bidPlusAskTradeEurUsd / 2))
        : 0
    }
  }
  return 0
}
