import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { subscribeTickSymbol, unsubscribeTickSymbol } from '../actions/marketData'
import {
  subscribeToPositions,
  unsubscribeFromPositions,
  setCalculatedPositions,
} from '../actions/positions'
import { findInstrument, fmtDateLong, getEurExchangeRate } from '../common/utilities'
import { I18nContext } from './i18n'

import { find, propEq } from 'ramda'

function PositionCalculator({
  // State
  tick,
  positions = undefined,
  positionIds = [],
  accountsWithPositions = [],
  activePanels = 0,
  positionSubscribed = false,
  instruments = undefined,
  instrumentIds = [],
  accounts = undefined,
  accountIds = [],
  currentAccount,
  accountGroups,
  // Dispach
  subscribeToPositions,
  unsubscribeFromPositions,
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  setCalculatedPositions,
}) {
  const [exchanges, setExchanges] = useState([])
  const { t } = useContext(I18nContext)

  const [render, setRender] = useState({})
  useEffect(() => {
    const interval = setInterval(() => setRender({}), 500)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!activePanels && positionSubscribed) {
      exchanges.forEach(exchangeId =>
        instrumentIds.forEach(instrumentId => {
          if (instrumentId.startsWith(exchangeId)) {
            const instrument = instruments[instrumentId]
            if (instrument) {
              unsubscribeTickSymbol(instrument.symbol)
            }
          }
        })
      )
      unsubscribeFromPositions()
      setExchanges([])
    } else if (activePanels && !positionSubscribed && accountIds.length > 0) {
      subscribeToPositions()
    }
  }, [activePanels, positionSubscribed, accountIds])

  useEffect(() => {
    if (!activePanels) {
      return
    }

    const exchangeIds = positionIds.reduce((acc, positionId) => {
      const positionExchangeId = accounts[positions[positionId].account].exchangeId
      if (!acc.includes(positionExchangeId)) {
        return [...acc, positionExchangeId]
      }
      return acc
    }, [])

    // Every time the positionIds changes the subs/unsubs must be triggered for every new or removed instrument
    subscriptionHandler(exchanges, exchangeIds)

    setExchanges(exchangeIds)
  }, [positionIds, activePanels])

  /**
   * Unsubscribe from all the instruments contained in the exchangeIds removed from positions and
   * subcribe to all instruments contained in the exchangeIds added to positions
   */
  function subscriptionHandler(oldExchangesIds, newExchangesIds) {
    oldExchangesIds
      .filter(e => !newExchangesIds.includes(e))
      .forEach(exchangeId =>
        instrumentIds.forEach(instrumentId => {
          if (instrumentId.startsWith(exchangeId)) {
            const instrument = instruments[instrumentId]
            if (instrument) {
              unsubscribeTickSymbol(instrument.symbol)
            }
          }
        })
      )
    newExchangesIds
      .filter(e => !oldExchangesIds.includes(e))
      .forEach(exchangeId =>
        instrumentIds.forEach(instrumentId => {
          if (instrumentId.startsWith(exchangeId)) {
            const instrument = instruments[instrumentId]
            if (instrument) {
              subscribeTickSymbol({ symbol: instrument.symbol, exchangeId: instrument.exchangeId })
            }
          }
        })
      )
  }

  function getNetPositionValue(netPosition, instrument) {
    return (instrument && +instrument.contractMultiplier * +netPosition).toString() || ''
  }

  function getUnrealized(position, netPositionValue) {
    if (tick) {
      const symbol = findInstrument(position.symbol, tick)
      if (symbol) {
        if (position.netPosition > 0) {
          return symbol.bidPrice
            ? ((+symbol.bidPrice - +position.averagePrice) * +netPositionValue).toFixed(2)
            : ''
        } else {
          return symbol.askPrice
            ? ((+symbol.askPrice - +position.averagePrice) * +netPositionValue).toFixed(2)
            : ''
        }
      }
    }
    return 0
  }

  function convertToEuro(quantity, quote) {
    if (quote === 'EUR') {
      return quantity
    }
    return quantity ? quantity * getEurExchangeRate(quote, tick) : 0
  }

  function buildPositionData() {
    return positionIds.map(p => {
      const accountInfo = accounts[positions[p].account]
      const instrument =
        instruments[`${accounts[positions[p].account].exchangeId}:${positions[p].symbol}`]
      const base = instrument && instrument.baseCurrency
      const quote = instrument && instrument.quoteCurrency
      const netPos = getNetPositionValue(positions[p].netPosition, instrument) || 0
      const unrealizedpl = getUnrealized(positions[p], netPos) || 0
      const unrealizedeur = convertToEuro(unrealizedpl, quote) || 0
      const realizedProfitEUR = convertToEuro(positions[p].realizedProfit, quote) || 0
      const deferralVariationMarginEUR =
        convertToEuro(positions[p].deferralVariationMargin, quote) || 0
      return {
        key: p,
        ...positions[p],
        account: accountInfo
          ? `${accountInfo.name} (${accountInfo.fixAccount})`
          : positions[p].account,
        accountId: positions[p].account,
        format: fmtDateLong(positions[p].date),
        netPositionFormat: +positions[p].netPosition,
        netPositionValue: +netPos,
        averagePriceFormat: +positions[p].averagePrice.toFixed(instrument.priceDecimals || 6),
        realizedProfit: positions[p].realizedProfit,
        realizedProfitEURValue:
          (realizedProfitEUR || realizedProfitEUR === 0) && +realizedProfitEUR.toFixed(2),
        unrealizedPnL: (unrealizedpl || unrealizedpl === 0) && +unrealizedpl,
        unrealizedPnLEURValue: (unrealizedeur || unrealizedeur === 0) && +unrealizedeur.toFixed(2),
        deferralVariationMargin: positions[p].deferralVariationMargin,
        deferralVariationMarginEURValue:
          (deferralVariationMarginEUR || deferralVariationMarginEUR === 0) &&
          +deferralVariationMarginEUR.toFixed(2),
        base: base,
        quote: quote,
      }
    })
  }

  function accountFilter(row) {
    switch (currentAccount.type) {
      case 'group':
        const p = find(propEq('id', currentAccount.id))(accountGroups)
        return Object.values(p?.accounts)
          .map(a => a.value)
          .includes(row.accountId)
      case 'account':
      default:
        return row.accountId === currentAccount.id
    }
  }

  function buildAccountsSummaries(positionsData) {
    return accountsWithPositions.map(accountId => buildAccountSummary(accountId, positionsData))
  }

  function buildAccountSummary(accountId, positionsData) {
    let realizedProfitEURValue = 0
    let unrealizedPnLEURValue = 0
    let deferralVariationMarginEURValue = 0
    const accountInfo = accounts[accountId]
    let customerAccountMaxMargin = undefined
    let customerAccountTotalMargin = undefined
    let operationAccountTotalMargin = undefined
    let remainingCollateral = undefined

    positionsData.forEach(p => {
      if (p.accountId === accountId) {
        realizedProfitEURValue = realizedProfitEURValue + p.realizedProfitEURValue
        unrealizedPnLEURValue = unrealizedPnLEURValue + p.unrealizedPnLEURValue
        if (!customerAccountMaxMargin) {
          customerAccountMaxMargin = p.customerAccountMaxMargin
        }
        if (!customerAccountTotalMargin) {
          customerAccountTotalMargin = p.customerAccountTotalMargin
        }
        if (!operationAccountTotalMargin) {
          operationAccountTotalMargin = p.operationAccountTotalMargin
        }
        if (!remainingCollateral) {
          remainingCollateral =
            (parseFloat(customerAccountMaxMargin) - parseFloat(customerAccountTotalMargin)).toFixed(
              2
            ) || ''
        }
        deferralVariationMarginEURValue =
          deferralVariationMarginEURValue + p.deferralVariationMarginEURValue
      }
    })

    return {
      key: accountId,
      account: accountInfo ? `${accountInfo.name} (${accountInfo.fixAccount})` : accountId,
      realizedProfitEURValue: (realizedProfitEURValue && realizedProfitEURValue.toFixed(2)) || 0,
      unrealizedPnLEURValue: (unrealizedPnLEURValue && unrealizedPnLEURValue.toFixed(2)) || 0,
      customerAccountMaxMargin: customerAccountMaxMargin && +customerAccountMaxMargin,
      customerAccountTotalMargin:
        customerAccountTotalMargin && (+customerAccountTotalMargin).toFixed(2),
      operationAccountTotalMargin:
        operationAccountTotalMargin && (+operationAccountTotalMargin).toFixed(2),
      remainingCollateral,
      deferralVariationMarginEURValue:
        (deferralVariationMarginEURValue && deferralVariationMarginEURValue.toFixed(2)) || 0,
    }
  }

  useEffect(() => {
    if (positionIds && positionIds.length) {
      const positionsData = buildPositionData()
      setCalculatedPositions({
        calculatedPositions: positionsData,
        calculatedAccountPositions: (currentAccount && positionsData.filter(accountFilter)) || [],
        accountsSummaries: buildAccountsSummaries(positionsData),
      })
    }
  }, [render, t, currentAccount])

  return null
}

const mapStateToProps = ({
  marketData: { tick },
  positions: {
    data: positions,
    dataIds: positionIds,
    accountsWithPositions,
    count: activePanels,
    positionSubscribed,
  },
  instruments: { data: instruments, dataIds: instrumentIds },
  accounts: { data: accounts, dataIds: accountIds },
  workspace: { currentAccount, accountGroups },
}) => ({
  tick,
  positions,
  positionIds,
  accountsWithPositions,
  activePanels,
  positionSubscribed,
  instruments,
  instrumentIds,
  accounts,
  accountIds,
  currentAccount,
  accountGroups,
})

const mapDispatchToProps = {
  subscribeToPositions,
  unsubscribeFromPositions,
  subscribeTickSymbol,
  unsubscribeTickSymbol,
  setCalculatedPositions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionCalculator)
