/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import { addWorkspace, changeWorkspace } from '../../actions/workspace'
import { I18nContext } from '../../containers/i18n'
import { MenuItem } from '../../common/utilities'
import { widgetsEnum } from '../../common/enum'

const Widgets = ({
  onClose,
  addWorkspace,
  changeWorkspace,
  anchorEl,
  open,
  addNewWorkspace,
  createSection,
  onAddClickOnTabSet,
  account,
  workspaces,
}) => {
  const { t } = useContext(I18nContext)
  const handleAddWorkspace = () => {
    onClose()
    addWorkspace(t('newWorkspace'))
    changeWorkspace(workspaces.length)
  }

  const widgetMenuItem = widgetEnum => (
    <MenuItem
      onClick={() => {
        onClose()
        setTimeout(() => {
          addNewWorkspace ? createSection(widgetEnum.type) : onAddClickOnTabSet(widgetEnum.type)
        })
      }}
    >
      {t(widgetEnum.addLiteral)}
    </MenuItem>
  )

  return (
    <Menu
      id="menu-appbar"
      disableAutoFocusItem
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      MenuListProps={{ disablePadding: true }}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: 'none',
        },
      }}
    >
      {addNewWorkspace && (
        <MenuItem data-test-id="new-workspace" onClick={() => handleAddWorkspace()}>
          {t('newWorkspace')}
        </MenuItem>
      )}
      {widgetMenuItem(widgetsEnum.SPLIT_QUOTE)}
      {widgetMenuItem(widgetsEnum.DEPTH_SPLIT_QUOTE)}
      {widgetMenuItem(widgetsEnum.TRADE)}
      {widgetMenuItem(widgetsEnum.ORDER_LIST)}
      {widgetMenuItem(widgetsEnum.TRADE_LIST)}
      {widgetMenuItem(widgetsEnum.PRICE_LIST)}
      {widgetMenuItem(widgetsEnum.QUOTE_LIST)}
      {widgetMenuItem(widgetsEnum.POSITION_LIST)}
      {widgetMenuItem(widgetsEnum.MARKET_DEPTH)}
      <MenuItem
        onClick={() => {
          onClose()
          setTimeout(() => {
            addNewWorkspace
              ? createSection('ACCOUNT_POSITION_LIST', account)
              : onAddClickOnTabSet('ACCOUNT_POSITION_LIST', account)
          })
        }}
      >
        {t('addAccountPositionList')}
      </MenuItem>
      {widgetMenuItem(widgetsEnum.ACCOUNT_SUMMARY_LIST)}
      {widgetMenuItem(widgetsEnum.ACCOUNT_GROUP)}
    </Menu>
  )
}

const mapStateToProps = ({ workspace: { account, workspaces } }) => ({
  workspaces,
  account,
})
const mapDispatchToProps = { addWorkspace, changeWorkspace }

export default connect(mapStateToProps, mapDispatchToProps)(Widgets)
