/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import * as style from './style'
import { useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { Add, Search, Create, Payment, ViewList } from '@material-ui/icons'
import { InputAdornment, MenuList, OutlinedInput } from '@material-ui/core'

import { MenuItem } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'

const filterData = (filter, accountGroup) => {
  const upperCasedFilter = filter.toUpperCase()
  return (
    accountGroup.name.toUpperCase().includes(upperCasedFilter) ||
    accountGroup.exchangeId?.toUpperCase().includes(upperCasedFilter) ||
    accountGroup.client?.shortName?.toUpperCase().includes(upperCasedFilter)
  )
}

const handleSearch = ({ filter, setFilteredData, data }) => {
  let filtered = data
  if (filter !== '') {
    filtered = data.filter(accountGroup => filterData(filter, accountGroup))
  }
  setFilteredData(filtered)
}

const handleEnter = ({ filter, data, changeCurrentAccount }) => {
  const accountGroupsList = data.find(
    accountGroup => accountGroup.name.toUpperCase() === filter.toUpperCase()
  )
  accountGroupsList &&
    changeCurrentAccount({
      name: accountGroupsList.name,
      type: accountGroupsList.type,
      id: accountGroupsList.id,
    })
}

const handleScrollTo = listContainerRef => {
  listContainerRef.current.scrollTop = 0
}

const accountTypes = { GROUP: 'group', ACCOUNT: 'account' }
const getAccountName = account => {
  if (account.type === accountTypes.GROUP) {
    return `${account.name}`
  }
  const client = account.client?.shortName ? `${account.client?.shortName}: ` : ''
  return `${client}${account.name}`
}

const AccountGroupsSearch = ({
  accountGroups,
  accountGroup,
  changeCurrentAccount,
  accounts,
  onEditAccountGroup,
}) => {
  const accountsList = accounts.dataIds.map(accountId => ({
    ...accounts.data[accountId],
    type: accountTypes.ACCOUNT,
  }))
  const accountGroupList = accountGroups?.map(obj => ({ ...obj, type: accountTypes.GROUP }))
  const [data] = useState([...accountGroupList, ...accountsList] || [])
  const [filteredData, setFilteredData] = useState(data || [])
  const [filter, setFilter] = useState(accountGroup)
  const listContainerRef = useRef()
  const { t } = useContext(I18nContext)
  const { colorMode } = useThemeUI()
  return (
    <div>
      <div sx={style.container(colorMode)}>
        <div sx={{ ...style.inputContainer, ...style.cp }}>
          <div sx={{ ...style.input(), ...style.add }} onClick={() => onEditAccountGroup()}>
            {t('createAccountGroup')}
            <Add sx={style.createIcon} />
          </div>
        </div>
        <div sx={style.inputContainer}>
          <OutlinedInput
            autoFocus
            labelWidth={0}
            sx={style.input(colorMode)}
            id="search"
            value={filter}
            endAdornment={
              <InputAdornment position="end">
                <Search sx={style.searchIcon} />
              </InputAdornment>
            }
            onChange={event => {
              handleSearch({
                filter: event.target.value,
                setFilter,
                setFilteredData,
                data,
              })
              handleScrollTo(listContainerRef)
            }}
            onKeyPress={event =>
              event.key === 'Enter' &&
              handleEnter({
                filter: event.target.value,
                data,
                changeCurrentAccount,
              })
            }
            placeholder={t('search')}
          />
        </div>
        <div ref={listContainerRef} sx={style.resultBox}>
          <div>
            <MenuList disablePadding dense>
              {filteredData.map(accountGroup => (
                <MenuItem
                  sx={style.menuItem}
                  value={accountGroup.id}
                  key={accountGroup.id}
                  onClick={() =>
                    changeCurrentAccount({
                      name: accountGroup.name,
                      type: accountGroup.type,
                      id: accountGroup.id,
                    })
                  }
                >
                  <div sx={style.accountGroupContainer}>
                    <div sx={style.account}>
                      {accountGroup.type === accountTypes.GROUP ? <ViewList /> : <Payment />}
                      <span sx={style.accountGroupName}>{getAccountName(accountGroup)}</span>
                    </div>
                    {accountGroup.type === accountTypes.GROUP && (
                      <span>
                        <Create
                          sx={style.editIcon}
                          onClick={() => onEditAccountGroup(accountGroup)}
                        />
                      </span>
                    )}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ workspace: { accountGroups }, accounts }) => ({
  accountGroups,
  accounts,
})

export default connect(mapStateToProps, null)(AccountGroupsSearch)
