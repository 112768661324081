/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { sortStatus, tableColumns } from '../../common/utilities'
import * as style from './style'
import { numberCell } from '../../common/utilities/tableCellUtils'

const sides = ['BUY', 'SELL']
const status = [
  'NEW',
  'PARTIALLY_FILLED',
  'FILLED',
  'CANCELLED',
  'PENDING_CANCEL',
  'REJECTED',
  'PENDING_NEW',
  'PENDING_REPLACE',
]
const orderTypes = ['LIMIT', 'MARKET', 'STOP_LIMIT', 'APPLICATION']
const tifs = ['DAY', 'GTD', 'IOC', 'FOK']
const capacities = ['DEAL', 'MATCH', 'ANY_OTHER']
const execType = [
  'NEW',
  'TRADE',
  'CANCELLED',
  'EXPIRED',
  'PENDING_NEW',
  'REJECTED',
  'PENDING_CANCEL',
  'PENDING_REPLACE',
  'REPLACED',
  'ORDER_STATUS',
  'RESTATED',
  'TRADE_CANCEL',
  'TRADE_CORRECT',
  'TRIGGERED',
]
const defaultSorted = [{ id: 'formatDate', desc: true }]

const columnsHead = t => {
  /*
   *  Cell format for the Status column
   */
  const statusCell = ({ value }) =>
    value ? (
      <div
        sx={{
          ...style.statusContainer,
          ...(style[`${value.replace('_', '').toLowerCase()}Status`] || style.defaultStatus),
        }}
      >
        <span sx={style.statusText}>{t(value)}</span>
      </div>
    ) : (
      ''
    )

  const c = tableColumns(t)

  return [
    c.getSelectColumn({
      header: 'status',
      accessor: 'orderStatus',
      csvHeader: 'status',
      options: status,
      sortMethod: sortStatus,
      width: 170,
      Cell: statusCell,
    }),
    c.getColumn({ accessor: 'actions', skipCsv: true, sortable: false, filterable: false }),
    c.getColumn({ accessor: 'orderId', csvHeader: 'orderId', width: 245 }),
    c.getDateColumn({ header: 'dateLocal', csvHeader: 'date', accessor: 'formatDate', width: 220 }),
    c.getColumn({ header: 'account', csvHeader: 'account', accessor: 'formatAccount' }),
    c.getColumn({ header: 'instrument', csvHeader: 'instrument', accessor: 'symbol' }),
    c.getSelectColumn({ header: 'side', csvHeader: 'side', options: sides }),
    c.getFromToIntColumn({
      header: 'orderQuantity',
      accessor: 'formatQuantity',
      csvHeader: 'quantity',
      Cell: numberCell,
    }),
    c.getColumn({ accessor: 'currency', csvHeader: 'currency' }),
    c.getFromToFloatColumn({
      header: 'orderedPrice',
      accessor: 'formatPrice',
      csvHeader: 'price',
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'filledPrice',
      accessor: 'formatAveragePrice',
      csvHeader: 'filledPrice',
      Cell: numberCell,
    }),
    c.getSelectColumn({
      header: 'type',
      accessor: 'orderType',
      csvHeader: 'type',
      options: orderTypes,
    }),
    c.getSelectColumn({ header: 'expiry', accessor: 'tif', csvHeader: 'tif', options: tifs }),
    c.getSelectColumn({
      header: 'tradingCapacity',
      csvHeader: 'tradingCapacity',
      options: capacities,
    }),
    c.getFromToFloatColumn({
      header: 'pendingQuantity',
      accessor: 'formatPendingQuantity',
      csvHeader: 'pendingQuantity',
      Cell: numberCell,
    }),
    c.getColumn({ accessor: 'text', csvHeader: 'text' }),
    c.getColumn({ accessor: 'orderRejectText', csvHeader: 'orderRejectText' }),
    c.getDateColumn({
      header: 'lastUpdateDate',
      accessor: 'formatUpdateDate',
      csvHeader: 'updateDate',
      width: 285,
    }),
    c.getColumn({ accessor: 'clientOrderId', csvHeader: 'clientOrderId' }),
    c.getColumn({ accessor: 'orderCancelText', csvHeader: 'orderCancelText' }),
    c.getFromToIntColumn({ header: 'clientId', csvHeader: 'clientId' }),
    c.getFromToIntColumn({ header: 'decisorId', csvHeader: 'decisorId' }),
    c.getFromToIntColumn({ header: 'executorId', csvHeader: 'executorId' }),
    c.getFromToFloatColumn({ header: 'stopPrice', csvHeader: 'stopPrice', Cell: numberCell }),
    c.getDateColumn({
      header: 'expirationDate',
      accessor: 'formatExpirationDate',
      csvHeader: 'expirationDate',
      width: 220,
    }),
    c.getSelectColumn({
      header: 'execStatus',
      csvHeader: 'execStatus',
      Cell: ({ value }) => t(value),
      width: 220,
      options: execType,
    }),
    c.getColumn({ header: 'exchangeId', csvHeader: 'exchangeId' }),
  ]
}

export { columnsHead, defaultSorted }
