import { connect } from 'react-redux'
import { toUpper } from 'ramda'
import React, { useState, useContext, useEffect } from 'react'
import { useThemeUI } from 'theme-ui'

import NumberSelector from '../NumberSelector'
import DialogNumberInput from '../DialogNumberInput'
import GTDDatePicker from '../GTDDatePicker'
import { parseDateFromyyyMMdd } from '../../common/utilities'
import { Content } from './style'
import { Dialog, DialogTitle, DialogBanner, DialogContentRow, DialogRowElement } from '../Dialog'
import { I18nContext } from '../../containers/i18n'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import Actions from './actions'
import AccountSelector from './accountSelector'
import FormattedNumberText from '../FormattedNumber'

const valueOrZero = value => +value || 0

const ModalOrderEdit = ({
  open,
  title,
  data,
  onSave,
  onClose,
  token,
  //connect
  tick,
  instruments,
}) => {
  const [price, setPrice] = useState()
  const [quantity, setQuantity] = useState()
  const [clientId, setClientId] = useState()
  const [decisorId, setDecisorId] = useState()
  const [executorId, setExecutorId] = useState()
  const [expirationDate, setExpirationDate] = useState()
  const [stopPrice, setStopPrice] = useState()
  const [operationAccount, setOperationAccount] = useState()

  const { symbol, exchangeId } = data
  const { askPrice, bidPrice } = tick[symbol] || {}
  const { priceDecimals, minPriceIncrement } = instruments[`${exchangeId}:${symbol}`] || {}
  const { t } = useContext(I18nContext)

  const isTifGTD = data?.tif === 'GTD'
  const isOrderType = orderType => data?.orderType === orderType
  const isOrderTypeMarket = isOrderType('MARKET')
  const isOrderTypeStopLimit = isOrderType('STOP_LIMIT')

  const { colorMode } = useThemeUI()

  useEffect(() => {
    setPrice(valueOrZero(data?.price))
    setQuantity(valueOrZero(data?.quantity))
    setClientId(valueOrZero(data?.clientId))
    setDecisorId(valueOrZero(data?.decisorId))
    setExecutorId(valueOrZero(data?.executorId))
    setOperationAccount({ id: data?.operationAccount })
    isTifGTD &&
      setExpirationDate(
        data?.expirationDate ? parseDateFromyyyMMdd(data.expirationDate) : new Date()
      )
    isOrderTypeStopLimit && setStopPrice(valueOrZero(data?.stopPrice))
  }, [data])

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle id="edit-order-dialog-title" disableTypography colormode={colorMode}>
          {title || t('modifyOrder')}
        </DialogTitle>

        <DialogBanner buy={data.side === 'BUY'}>
          {/* Keep the non breaking spaces as is. If changed to normal spaces sometimes they will get removed */}
          {toUpper(t(data.side))}&nbsp;{<FormattedNumberText value={quantity} />}&nbsp;
          {data.symbol}&nbsp;@&nbsp;{<FormattedNumberText value={price} />}&nbsp;
          {toUpper(t(data.orderType))}&nbsp;
          {data.tif}
        </DialogBanner>

        <Content>
          <DialogContentRow>
            <AccountSelector
              operationAccount={operationAccount}
              exchangeId={data.exchangeId}
              onChange={setOperationAccount}
            />
          </DialogContentRow>
          <DialogContentRow>
            <NumberSelector label={t('amount')} value={quantity} onChange={setQuantity} />

            <NumberSelector
              disabled={isOrderTypeMarket}
              decimals={priceDecimals}
              increment={minPriceIncrement}
              label={isOrderTypeStopLimit ? t('shortStopPrice') : t('price')}
              onChange={isOrderTypeStopLimit ? setStopPrice : setPrice}
              value={isOrderTypeStopLimit ? stopPrice : price}
              askPrice={askPrice}
              bidPrice={bidPrice}
            />
          </DialogContentRow>

          {
            //GTD y Stop Limit
          }
          <DialogContentRow>
            {isTifGTD ? (
              <GTDDatePicker
                sx={{ width: '50%' }}
                label={t('GTD')}
                value={expirationDate}
                onChange={setExpirationDate}
                labelProps={{ sx: { bg: 'disabled', mr: '20px' } }}
              ></GTDDatePicker>
            ) : (
              <DialogRowElement width="50%" />
            )}
            {isOrderTypeStopLimit ? (
              <NumberSelector
                sx={{ width: '50%' }}
                decimals={priceDecimals}
                increment={minPriceIncrement}
                label={t('shortLimitPrice')}
                value={price}
                onChange={setPrice}
              />
            ) : (
              <DialogRowElement width="50%" />
            )}
          </DialogContentRow>

          {
            //identificadores de ORK (Client, Decisor y executor)
          }
          <PermissionsVisible anyRoles={['member_trader']}>
            <DialogContentRow>
              <DialogNumberInput label={t('clientId')} value={clientId} onChange={setClientId} />
              <DialogNumberInput label={t('decisorId')} value={decisorId} onChange={setDecisorId} />
              <DialogNumberInput
                label={t('executorId')}
                value={executorId}
                onChange={setExecutorId}
              />
            </DialogContentRow>
          </PermissionsVisible>
        </Content>

        <Actions
          {...{
            data: {
              ...data,
              price,
              quantity,
              clientId,
              decisorId,
              executorId,
              expirationDate,
              stopPrice,
              operationAccount,
            },
            onSave,
            onClose,
            token,
          }}
        />
      </Dialog>
    )
  )
}

const mapStateToProps = ({ marketData: { tick }, instruments: { data } }) => ({
  tick,
  instruments: data,
})

export default connect(mapStateToProps, null)(ModalOrderEdit)
