export default (priceData, instrument) => {
  if (priceData && instrument && priceData.split('.').length > 1) {
    const price = priceData.split('.')
    const firstPosition =
      instrument.priceDecimals - (instrument.priceDecimals - instrument.pipDecimal)
    const lastPosition = firstPosition - instrument.pipDigits
    return {
      bigFigure: `${price[0]}.${price[1].substring(0, lastPosition)}`,
      pips: price[1].substring(firstPosition, lastPosition),
      fractionalPips:
        price.length > 2
          ? `${price[1].substring(firstPosition, instrument.priceDecimals)}${price
              .slice(2)
              .reduce((x, y) => `${x}.${y}`, '')}`
          : price[1].substring(firstPosition, instrument.priceDecimals),
      error: price.length > 2,
    }
  } else {
    return {
      bigFigure: priceData,
      pips: '',
      fractionalPips: '',
      error: isNaN(priceData),
    }
  }
}
