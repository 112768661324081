/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'

import CustomButton from '../CustomButton'
import { DialogActions } from '../Dialog'
import { I18nContext } from '../../containers/i18n'
import { connect } from 'react-redux'
import { cleanOrderData } from '../../actions/orders'

const ModalActions = ({ onClose, onCancel, onModify, status, order, cleanOrderData }) => {
  const { t } = useContext(I18nContext)

  const ACTIONS = { MODIFY: 'MODIFY', CANCEL: 'CANCEL', CLOSE: 'CLOSE' }
  const handleAction = action => {
    switch (action) {
      case ACTIONS.MODIFY:
        cleanOrderData()
        onModify({ viewMode: false })
        break
      case ACTIONS.CANCEL:
        onCancel()
        break
      case ACTIONS.CLOSE:
      default:
        cleanOrderData()
        onClose()
        break
    }
  }

  const onlyCloseStatuses = [
    'FILLED',
    'CANCELLED',
    'REJECTED',
    'PENDING_CANCEL',
    'PENDING_NEW',
    'PENDING_REPLACE',
  ]

  const isApplicationOrder = order => order && order.data && order.data.orderType === "APPLICATION"

  const isOnlyClose = (status, order) => onlyCloseStatuses.includes(status) || isApplicationOrder(order)

  return (
    <DialogActions>
      {isOnlyClose(status, order) ? (
        <CustomButton
          type="cancel"
          noMargin
          onClick={() => handleAction(ACTIONS.CLOSE)}
          width="30%"
        >
          {t('close')}
        </CustomButton>
      ) : (
        <>
          <CustomButton type="primary" noMargin onClick={() => handleAction(ACTIONS.MODIFY)}>
            {t('modifyOrder')}
          </CustomButton>
          <CustomButton type="primary" noMargin onClick={() => handleAction(ACTIONS.CANCEL)}>
            {t('cancelOrder')}
          </CustomButton>
          <CustomButton type="primary" noMargin onClick={() => handleAction(ACTIONS.CLOSE)}>
            {t('close')}
          </CustomButton>
        </>
      )}
    </DialogActions>
  )
}

const mapDispatchToProps = {
  cleanOrderData,
}

export default connect(null, mapDispatchToProps)(ModalActions)
