/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useState, useContext, memo } from 'react'

import * as style from './style'
import SplitQuoteArrow from '../SplitQuoteArrow'
import { renderPips, orkValidate, searchAccount } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'
import { showErrorDialog } from '../../actions/workspace'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { PermissionsContext } from '../../containers/PermissionsProvider'

const getMake = ({
  payload,
  currentAccount,
  accountGroups,
  accounts,
  instrument,
  workspaces,
  currentWorkspace,
  tabIndex,
  spotIndex,
  hasAnyRole,
  t,
  isConfirmDialogRequired,
  makeOrderWithConfirm,
  makeOrder,
  keycloak,
  handleModal,
}) => {
  const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)
  if (selectedAccount) {
    const { amountToOperate, clientId, decisorId, executorId, tradingCapacity } = workspaces[
      currentWorkspace
    ].tabs[tabIndex].spots[spotIndex]
    const isMemberTrader = hasAnyRole(['member_trader'])
    const trading = {
      clientId: clientId,
      decisorId: decisorId,
      executorId: executorId,
      tradingCapacity: tradingCapacity,
    }
    const info = {
      symbol: payload.currency,
      exchangeId: payload.exchangeId,
      side: payload.direction,
      orderType: 'MARKET',
      quantity: amountToOperate,
      tif: 'DAY',
      operationAccount: selectedAccount.id,
    }
    const infoData = isMemberTrader ? { ...info, ...trading } : { ...info }

    const message = `${t(payload.action).toUpperCase()} ${amountToOperate}
    ${payload.currency} ${t('MARKET').toUpperCase()} ${t('DAY').toUpperCase()}`
    !isMemberTrader || orkValidate({ ...trading }).isValid
      ? isConfirmDialogRequired
        ? makeOrderWithConfirm(
            infoData,
            { body: message, title: t('confirmOrder'), selectedAccount: selectedAccount },
            keycloak.token
          )
        : makeOrder(infoData, keycloak.token)
      : handleModal(t('orkError').toUpperCase())
  } else {
    handleModal(t('accountError'))
  }
}

const PriceButton = ({
  ask,
  priceData,
  currency,
  instrument,
  lastPriceData,
  isConfirmDialogRequired,
  spotIndex,
  tabIndex,
  workspaces,
  currentWorkspace,
  makeOrderWithConfirm,
  makeOrder,
  keycloak,
  showErrorDialog,
  accountGroups,
  currentAccount,
  accounts,
}) => {
  const { t } = useContext(I18nContext)
  const [hover, setHover] = useState(false)
  const { hasAnyRole } = useContext(PermissionsContext)

  const direction = ask ? 'BUY' : 'SELL'
  const action = ask ? 'BUYACTION' : 'SELLACTION'
  const price = priceData !== '' ? renderPips(priceData, instrument) : ''
  const base = price.bigFigure ? instrument.baseCurrency : ''

  const { colorMode } = useThemeUI()

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const make = payload => {
    getMake({
      payload,
      currentAccount,
      accountGroups,
      accounts,
      instrument,
      workspaces,
      currentWorkspace,
      tabIndex,
      spotIndex,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  return (
    <>
      <div
        sx={style.setStyle(ask)}
        onClick={() =>
          make({
            currency,
            direction,
            action,
            priceData,
            exchangeId: instrument.exchangeId,
            title: t('confirmOrder'),
          })
        }
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div sx={style.direction(colorMode)}>
          {t(action)} {base}
        </div>
        <span sx={style.bigFigure(colorMode)}>
          <span sx={style.fig(colorMode)}>{price.bigFigure}</span>
          <br />
          <span sx={style.pip(colorMode)}>{price.pips} </span>
          <span
            sx={
              price?.fractionalPips?.length > 1
                ? style.fractionalPipsLong(colorMode)
                : style.fractionalPips(colorMode)
            }
          >
            {price.fractionalPips}
          </span>
        </span>
        {price.bigFigure && (
          <SplitQuoteArrow hover={hover} priceData={priceData} lastPriceData={lastPriceData} />
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({
  workspace: {
    workspaces,
    currentWorkspace,
    isConfirmDialogRequired,
    accountGroups,
    currentAccount,
  },
  accounts: { data: accounts },
}) => ({
  isConfirmDialogRequired,
  workspaces,
  currentWorkspace,
  accountGroups,
  currentAccount,
  accounts,
})
const mapDispatchToProps = {
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(PriceButton))
