/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import CustomButton from '../CustomButton'

export const sides = { BUY: 'BUY', SELL: 'SELL' }

const PriceCell = ({ side, onPriceClick, tick, instrument }) => {
  const safePrice = (currency, key, decimals) =>
    currency?.[key] ? Number(currency[key]).toFixed(decimals) : ''

  const askPriceFixed = currency => safePrice(tick[currency], 'askPrice', instrument.priceDecimals)
  const bidPriceFixed = currency => safePrice(tick[currency], 'bidPrice', instrument.priceDecimals)

  const priceFunction = side === sides.BUY ? askPriceFixed : bidPriceFixed
  const price = priceFunction(instrument.symbol)

  return (
    <CustomButton
      onClick={() => {
        onPriceClick(price, side)
      }}
      size="large"
      disabled={price ? false : true}
      type={`cell${side.toLowerCase()}`}
      sx={{ padding: '3px', transition: 'none' }}
      width="80%"
    >
      {price || '...'}
    </CustomButton>
  )
}

const mapStateToProps = ({ instruments: { data: instruments }, marketData: { tick } }) => ({
  instruments,
  tick,
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCell)
