/** @jsx jsx */
import { jsx } from 'theme-ui'
import { connect } from 'react-redux'
import { useContext, useMemo, useRef, useEffect } from 'react'
import * as style from './style'
import { I18nContext } from '../../containers/i18n'
import { CustomSelect, getOptions } from '../../common/utilities'
import { updateTradePanel } from '../../actions/workspace'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { addDays } from 'date-fns'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputMask from 'react-input-mask'

export const orderTypes = ['MARKET', 'LIMIT', 'STOP_LIMIT']

const Orders = ({
  orderType,
  orderTypeOnChange,
  orderDuration,
  expirationDate,
  tabIndex,
  updateTradePanel,
}) => {
  const { t } = useContext(I18nContext)

  const customDuration = useMemo(() => {
    switch (orderType) {
      case 'LIMIT':
        return ['DAY', 'FOK', 'IOC', 'GTD']
      case 'MARKET':
        return []
      case 'STOP_LIMIT':
        return ['DAY', 'GTD']
      default:
        return []
    }
  }, [orderType])

  const disabledDuration = useMemo(() => orderType === 'MARKET', [orderType])
  const disabledDate = useMemo(
    () => (disabledDuration ? disabledDuration : orderDuration !== 'GTD'),
    [disabledDuration, orderDuration]
  )

  const datePickerRef = useRef()

  //if date is before today, return today
  const validDate = stringDate =>
    new Date(Math.max(new Date().setHours(0, 0, 0, 0), new Date(Date.parse(stringDate) || 0)))

  useEffect(() => {
    expirationDate !== validDate(expirationDate) &&
      updateTradePanel({ expirationDate: validDate(expirationDate), tabIndex })
  }, [])

  return (
    <div sx={style.selects}>
      <CustomSelect
        IconComponent={ExpandMore}
        onChange={e => {
          orderTypeOnChange(e)
        }}
        style={style.mediumSize}
        sx={{ ...style.input, width: '42%', pl: '0px', fontSize: '2' }}
        value={orderType}
      >
        {getOptions(t, orderTypes)}
      </CustomSelect>
      <CustomSelect
        IconComponent={ExpandMore}
        onChange={e => {
          updateTradePanel({ orderDuration: e.target.value, tabIndex })
        }}
        style={style.mediumSize}
        sx={{
          ...style.input,
          width: '22%',
          pl: '0px',
          fontSize: '2',
          ...(disabledDuration ? style.disabled : {}),
        }}
        disabled={disabledDuration}
        value={orderDuration}
      >
        {getOptions(t, customDuration)}
      </CustomSelect>
      <div sx={{ ...style.input, width: '32%', ...style.mediumSize }}>
        <DatePicker
          ref={datePickerRef}
          disabled={disabledDate}
          dateFormat={t('dateFormat')}
          tabIndex={tabIndex}
          onChange={date => {
            updateTradePanel({ expirationDate: validDate(date), tabIndex })
          }}
          selected={disabledDate ? '' : validDate(expirationDate)}
          minDate={new Date()}
          maxDate={addDays(new Date(), 90)}
          popperPlacement="bottom-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 10px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
          customInput={
            <InputMask mask="99/99/99" alwaysShowMask maskChar="-">
              {() => (
                <Input
                  onClick={() => datePickerRef.current.input.onFocus()}
                  disableUnderline
                  endAdornment={
                    <InputAdornment disablePointerEvents>
                      <ExpandMore />
                    </InputAdornment>
                  }
                  sx={{
                    ...style.input,
                    ...style.mediumSize,
                    cursor: 'pointer',
                    width: 'auto',
                    pl: '10px',
                    ...(disabledDate ? style.disabled : {}),
                  }}
                  type="text"
                />
              )}
            </InputMask>
          }
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  updateTradePanel,
}

export default connect(null, mapDispatchToProps)(Orders)
