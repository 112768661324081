/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useState, useEffect, useContext } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import * as style from './style'
import { setPos } from '../NewSpot/style'
import CustomButton from '../CustomButton'
import PriceSelector from '../PriceSelector'
import AmountSelector from '../AmountSelector'
import InstrumentSearch from '../InstrumentSearch'
import { I18nContext } from '../../containers/i18n'
import { renderPips, fmtDateyyyMMdd, orkValidate, searchAccount } from '../../common/utilities'
import { editTradePanel, updateTradePanel, showErrorDialog } from '../../actions/workspace'
import { subscribeTradeSymbol, unsubscribeTradeSymbol } from '../../actions/marketData'
import { makeOrderWithConfirm, makeOrder, connectToOrdersSocket } from '../../actions/orders'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import TradingCapacity from './tradingCapacity'
import Orders, { orderTypes } from './orders'
import FormattedNumberText from '../FormattedNumber'

const AMOUNT_VALUES = [1, 2, 5, 10, 20]

const BUY = 'BUY'
const BUYACTION = 'BUYACTION'
const SELL = 'SELL'
const SELLACTION = 'SELLACTION'

const TradePanel = ({
  tabIndex,
  editTradePanel,
  data = {},
  instruments,
  updateTradePanel,
  currency,
  subscribeTradeSymbol,
  unsubscribeTradeSymbol,
  makeOrder,
  makeOrderWithConfirm,
  isConfirmDialogRequired,
  keycloak,
  showErrorDialog,
  accountGroups,
  currentAccount,
  accounts,
}) => {
  const [editPrice, setEditPrice] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [instrument, setInstrument] = useState({})
  const [editStopPrice, setEditStopPrice] = useState(false)
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 })
  const [lastClick, setLastClick] = useState(null)
  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)

  const isMarket = data.orderType === orderTypes[0]
  const currentPrice = data.price

  const { colorMode } = useThemeUI()

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  // Subscribe to symbol on mount
  useEffect(() => {
    data &&
      data.selectedInstrumentSymbol &&
      data.selectedInstrumentExchangeId &&
      subscribeTradeSymbol({
        symbol: data.selectedInstrumentSymbol,
        exchangeId: data.selectedInstrumentExchangeId,
      })
  }, [])

  useEffect(() => {
    const newInstrument =
      instruments[`${data.selectedInstrumentExchangeId}:${data.selectedInstrumentSymbol}`]
    // Avoid undefinded subscriptions
    newInstrument &&
      subscribeTradeSymbol({ symbol: newInstrument.symbol, exchangeId: newInstrument?.exchangeId })
    setEditPrice(false)
    setInstrument(newInstrument)
    if (data.orderType === 'MARKET') {
      setDisabled(true)
    }
    return () => unsubscribeTradeSymbol(data.selectedInstrumentSymbol)
  }, [data.selectedInstrumentSymbol, data.selectedInstrumentExchangeId])

  const onSelectInstrument = (instrument, tabIndex) => {
    data.selectedInstrumentSymbol && unsubscribeTradeSymbol(data.selectedInstrumentSymbol)
    updateTradePanel({
      selectedInstrumentSymbol: instrument.symbol,
      selectedInstrumentExchangeId: instrument.exchangeId,
      price: 0,
      stopPrice: 0,
      tabIndex,
      status: 'VIEW',
    })
    subscribeTradeSymbol({ symbol: instrument.symbol, exchangeId: instrument.exchangeId })
    setInstrument(instrument)
  }

  const setAmount = value => {
    const { amount: currentAmount } = data
    const now = new Date()
    const diff = lastClick?.getTime() - now.getTime()
    updateTradePanel({
      amount: diff && Math.abs(diff) < 2000 ? value + currentAmount : value,
      tabIndex,
    })
    setLastClick(now)
  }

  const makeOperation = () => {
    const isMemberTrader = hasAnyRole(['member_trader'])
    const selectedAccount = searchAccount(currentAccount, accountGroups, accounts, instrument)
    if (selectedAccount) {
      const trading = {
        clientId: data.clientId,
        decisorId: data.decisorId,
        executorId: data.executorId,
        tradingCapacity: data.tradingCapacity,
      }
      const info = {
        symbol: instrument.symbol,
        exchangeId: instrument.exchangeId,
        operationAccount: selectedAccount.id,
        side: data.action.toUpperCase(),
        orderType: data.orderType,
        quantity: data.amount,
        currency: instrument.settlementCurrency,
        tif: data.orderDuration,
        price: data.price || currency.price,
        stopPrice: data.stopPrice || 0,
        ...(['GTD'].includes(data.orderDuration) &&
          data.expirationDate && { expirationDate: fmtDateyyyMMdd(data.expirationDate) }),
      }
      const payload = isMemberTrader ? { ...info, ...trading } : { ...info }
      !isMemberTrader || orkValidate(data).isValid
        ? isConfirmDialogRequired
          ? makeOrderWithConfirm(
              payload,
              { body: null, title: t('confirmOrder'), selectedAccount: selectedAccount },
              keycloak.token
            )
          : makeOrder(payload, keycloak.token)
        : handleModal(t('orkError').toUpperCase())
    } else {
      handleModal(t('accountError'))
    }
  }

  const socketPrice = currency?.price || 0
  const price = renderPips(
    data.price === '' || data.price
      ? data.price
      : socketPrice && Number(socketPrice).toFixed(instrument.priceDecimals),
    instrument
  )

  const socketStopPrice = currency?.price || 0
  const stopPrice = renderPips(
    data.stopPrice === '' || data.stopPrice
      ? data.stopPrice
      : socketStopPrice && Number(socketStopPrice).toFixed(instrument.priceDecimals),
    instrument
  )

  const socketLimitPrice = data.price || currency?.price || 0
  const limitPrice = renderPips(
    data.price === '' || data.price
      ? data.price
      : socketLimitPrice && Number(socketLimitPrice).toFixed(instrument.priceDecimals),
    instrument
  )

  const priceComplete = price.bigFigure + price.pips + price.fractionalPips

  const isDisabled = () => {
    if (data && data.selectedInstrumentSymbol && data.selectedInstrumentExchangeId) {
      if (isMarket && priceComplete >= 0 && data.amount > 0) {
        return false
      } else {
        return price.error || priceComplete <= 0 || !data.amount || data.amount <= 0
      }
    }
    return true
  }

  return (
    <>
      <div sx={style.container}>
        <div sx={style.flex}>
          <CustomButton
            onClick={() => updateTradePanel({ action: 'BUY', tabIndex })}
            size="large"
            type={data.action === BUY ? BUY.toLowerCase() : 'cancel'}
            sx={{ ml: '0px' }}
            width="50%"
          >
            {t(BUYACTION)}
          </CustomButton>
          <CustomButton
            onClick={() => updateTradePanel({ action: 'SELL', tabIndex })}
            size="large"
            type={data.action === SELL ? SELL.toLowerCase() : 'cancel'}
            sx={{ mr: '0px', ml: '4px' }}
            width="50%"
          >
            {t(SELLACTION)}
          </CustomButton>
        </div>
        <div
          sx={style.instrument}
          onClick={e => {
            const { top, left } = e.target.offsetParent.getBoundingClientRect()
            setPosition({ x: left + 90, y: top + 100 })
            editTradePanel(tabIndex)
          }}
        >
          {data?.selectedInstrumentSymbol || t('selectInstrument')}
        </div>
        <>
          <AmountSelector
            action={setAmount}
            styles={{ height: '44px', fontSize: 'large', width: '100%', minwidth: '0px' }}
            tabIndex={tabIndex}
            updateFn={updateTradePanel}
            value={data.amount}
            endAdornmentText={t('QTY')}
          />
          <div sx={style.amounts(colorMode)}>
            {AMOUNT_VALUES.map(value => (
              <button key={value} onClick={() => setAmount(value)}>
                {value}
              </button>
            ))}
          </div>
        </>
        <Orders
          tabIndex={tabIndex}
          updateTradePanel={updateTradePanel}
          orderDuration={data.orderDuration}
          orderType={data.orderType}
          orderTypeOnChange={e => {
            updateTradePanel({
              orderType: e.target.value,
              tabIndex,
              orderDuration: e.target.value === 'MARKET' ? 'DAY' : data.orderDuration,
              price:
                e.target.value === 'MARKET' || e.target.value === 'STOP_LIMIT'
                  ? 0
                  : socketPrice || data.price,
              stopPrice:
                e.target.value === 'MARKET' || e.target.value === 'LIMIT'
                  ? 0
                  : socketPrice || data.stopPrice,
            })
            e.target.value === 'MARKET' ? setDisabled(true) : setDisabled(false)
          }}
          expirationDate={data.expirationDate}
        />
        <>
          {data.orderType === 'STOP_LIMIT' ? (
            <div sx={{ width: '100%' }}>
              <PriceSelector
                disabled={disabled}
                type={'stopPrice'}
                editPrice={editStopPrice}
                enableEditPrice={setEditStopPrice}
                instrument={instrument}
                price={stopPrice}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('stopPrice')}
                currentPrice={currentPrice}
              />
              <PriceSelector
                disabled={disabled}
                type={'price'}
                editPrice={editPrice}
                enableEditPrice={setEditPrice}
                instrument={instrument}
                price={limitPrice || 0}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('limitPrice')}
                currentPrice={currentPrice}
              />
            </div>
          ) : (
            <div sx={{ width: '100%' }}>
              <PriceSelector
                disabled={disabled}
                type={'price'}
                editPrice={editPrice}
                enableEditPrice={setEditPrice}
                instrument={instrument}
                price={price}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('price')}
                currentPrice={currentPrice}
              />
              <PriceSelector disabled={true} />
            </div>
          )}
        </>
        <PermissionsVisible anyRoles={['member_trader']}>
          <TradingCapacity value={data} tabIndex={tabIndex} />
        </PermissionsVisible>
        <div sx={style.buttons}>
          <CustomButton
            type={!isDisabled() ? data?.action.toLowerCase() : 'disabled'}
            size="large"
            width="100%"
            onClick={() => !isDisabled() && makeOperation()}
          >
            <span>
              {t(data.action === BUY ? BUYACTION : SELLACTION)}&nbsp;
              <FormattedNumberText value={data.value} />
              &nbsp;
              {data?.selectedInstrumentSymbol || ''}&nbsp;
              {data.orderType !== 'MARKET' ? (
                <FormattedNumberText value={priceComplete} prefix="@ " suffix=" " />
              ) : (
                ''
              )}
              {t(data.orderType)}&nbsp;
              {data.orderType !== 'MARKET' ? t(data.orderDuration) : ''}
            </span>
          </CustomButton>
        </div>
      </div>
      {data.status === 'EDIT' && (
        <div sx={setPos([x, y])}>
          <ClickAwayListener onClickAway={() => editTradePanel(tabIndex)}>
            <InstrumentSearch
              search={data?.selectedInstrumentSymbol || ''}
              tabIndex={tabIndex}
              onSelectInstrument={onSelectInstrument}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (
  {
    instruments: { data: instruments },
    marketData: { trade: currencies },
    workspace: {
      isConfirmDialogRequired,
      currentWorkspace,
      workspaces,
      accountGroups,
      currentAccount,
    },
    accounts: { data: accounts },
  },
  ownProps
) => {
  const currentData = workspaces[currentWorkspace].tabs[ownProps.tabIndex]
  return {
    instruments,
    isConfirmDialogRequired,
    currentWorkspace,
    workspaces,
    accountGroups,
    currentAccount,
    accounts,
    data: currentData,
    currency: currencies[`${currentData?.selectedInstrumentSymbol}`],
  }
}

const mapDispatchToProps = {
  editTradePanel,
  updateTradePanel,
  unsubscribeTradeSymbol,
  subscribeTradeSymbol,
  makeOrder,
  makeOrderWithConfirm,
  connectToOrdersSocket,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(TradePanel)
