import Stomp from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import config from '../common/config'

let positionSuscription = null

const client = Stomp.over(() => new SockJS(config().urls.positions))
client.reconnect_delay = config().reconnectDelay
if (process.env.NODE_ENV !== 'development') {
  client.debug = () => {}
}

export const connectToPositionsSocket = payload => (dispatch, getState) => {
  if (!getState().positions.connected) {
    client.connect(
      { Authorization: `Bearer ${payload}` },
      () => {
        dispatch({ type: 'POSITION_SOCKET_CONNECTED' })
        subscribeToNotifications(dispatch)
      },
      null, // Error Callback
      dispatch({ type: 'POSITION_SOCKET_DISCONNECTED' })
    )
  }
}

export const subscribeToPositions = () => (dispatch, getState) => {
  positionSuscription = client.subscribe('/private/position/', data => {
    const { type, positions, position } = JSON.parse(data.body)
    switch (type) {
      case 'position_list':
        let accounts = {}
        let positionsObject = {}
        positions.forEach(p => {
          const positionKey = `${getState().accounts.data[p.account].exchangeId}:${p.symbol}:${
            p.account
          }`
          positionsObject = {
            ...positionsObject,
            [positionKey]: { ...p },
          }
          accounts = { ...accounts, [p.account]: {} }
        })
        dispatch({
          type: 'POSITION_LIST_DATA',
          payload: {
            positions: positionsObject,
            positionIds: Object.keys(positionsObject),
            accountsWithPositions: Object.keys(accounts),
          },
        })
        break
      case 'position_update':
        const key = `${getState().accounts.data[position.account].exchangeId}:${position.symbol}:${
          position.account
        }`
        dispatch({
          type: 'POSITION_LIST_UPDATE',
          payload: { position: { ...position }, key: key },
        })
        break
      default:
        break
    }
  })
  dispatch({ type: 'POSITIONS_SUBSCRIBED' })
}

export const unsubscribeFromPositions = () => dispatch => {
  positionSuscription && positionSuscription.unsubscribe()
  dispatch({ type: 'POSITIONS_UNSUBSCRIBED' })
}

const subscribeToNotifications = dispatch => {
  client.subscribe('/private/notification/', data => {
    const body = JSON.parse(data.body)
    const { type, notification } = body
    if (type === 'notification_update') {
      dispatch({ type: 'NOTIFICATION_UPDATE', payload: notification })
    }
  })
}

export const setPositionsTabs = () => (dispatch, getState) => {
  const prevState = getState()
  let count = 0
  prevState.workspace.workspaces.forEach(
    ws =>
      ws.tabs &&
      ws.tabs.length > 0 &&
      ws.tabs.forEach(tab => {
        if (
          tab.type === 'POSITION_LIST' ||
          tab.type === 'ACCOUNT_POSITION_LIST' ||
          tab.type === 'ACCOUNT_SUMMARY_LIST'
        ) {
          count = count + 1
        }
      })
  )
  dispatch({ type: 'SET_POSITION_TABS', payload: count })
}

export const reducePositionTabs = () => (dispatch, getState) => {
  const count = getState().positions.count
  dispatch({ type: 'SET_POSITION_TABS', payload: count === 0 ? 0 : count - 1 })
}

export const closeNotificationDialog = () => ({
  type: 'CLOSE_NOTIFICATION_DIALOG',
})

export const setCalculatedPositions = payload => dispatch =>
  dispatch({ type: 'SET_CALCULATED_POSITIONS', payload })
