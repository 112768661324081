import Stomp from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import uuid from 'uuid'
import config from '../common/config'

const client = Stomp.over(() => new SockJS(config().urls.rfq))
if (process.env.NODE_ENV !== 'development') {
  client.debug = null
}

const typesDontSave = ['accept_rfq']
let oid = uuid()

export const socketInit = payload => dispatch => {
  client.connect({ Authorization: `Bearer ${payload}` }, () =>
    dispatch({ type: 'SOCKET_CONNECTED', payload: true })
  )
}

export const socketSendMessage = payload => dispatch => {
  client.send(
    payload.topic,
    { Authorization: `Bearer ${payload.token}` },
    JSON.stringify({ ...payload.message, oid })
  )
  dispatch({ type: 'ADD_OID', payload: oid })

  if (!typesDontSave.includes(payload.message.type)) {
    dispatch({ type: 'SAVE_OID', payload: { oid: oid, index: payload.tabIndex } })
  }
  oid = uuid()
}

const subscribeSocket = ({ topic, callback }) => {
  client.subscribe(topic, data => callback(data))
}

export const hideSnackbar = payload => ({
  type: 'HIDE_SNACKBAR',
  payload: payload,
})

export const subscribeForRFQ = tabIndex => dispatch => {
  subscribeSocket({
    topic: '/private/rfq/updates',
    callback: data => {
      const socketData = JSON.parse(data.body)
      switch (socketData.type) {
        case 'rfq_information':
          dispatch({
            type: 'GET_RFQ',
            payload: { data: socketData, tabIndex },
          })
          dispatch({ type: 'GET_ID_FOR_OID', payload: socketData })
          break
        case 'rfq_quote_update':
          dispatch({ type: 'UPDATE_RFQ', payload: socketData })
          break
        case 'accept_rfq':
          dispatch({ type: 'SHOW_SNACKBAR', payload: socketData.rfqId })
          break
        case 'rfq_list':
          dispatch({ type: 'SAVE_RFQ_LIST', payload: socketData })
          break
        default:
          break
      }
    },
  })
}
