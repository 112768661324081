/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import React, { useState, useEffect, useRef, createRef } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import 'react-table/react-table.css'
import { remove, insert } from 'ramda'
import * as style from './style'

import { setFilters } from '../../actions/workspace'
import { setDownloadCsv } from '../../actions/csv'
import { dragableColumns } from '../TableAdmin/TableUtils'
import CSVDownload from '../CSV'

const visible = { overflow: 'visible' }

const filterCaseInsensitive = ({ id, value }, row) =>
  row[id] ? row[id].toUpperCase().includes(value.toUpperCase()) : true

const Table = ({
  tabIndex,
  rows,
  columns,
  filterable,
  setFilters,
  minRows = 3,
  setDownloadCsv,
  type,
  download,
  currentWorkspace,
  ...props
}) => {
  const [newColumns, setNewColumns] = useState([])
  const [newDownload, setNewDownload] = useState(false)

  const { theme, colorMode } = useThemeUI()

  const reactTable = useRef()

  useEffect(() => {
    setNewColumns([...columns])
  }, [columns])

  const onDropColumn = (dragIndex, dropIndex) => {
    const temp = remove(dragIndex, 1, newColumns)
    setNewColumns(insert(dropIndex, newColumns[dragIndex], temp))
  }

  useEffect(() => {
    dragableColumns({ colAux: newColumns, tabIndex, onDropColumn })
  }, [newColumns])

  useEffect(() => {
    setNewDownload(download)
    download && setDownloadCsv({ download: false, currentWorkspace, tabIndex })
  }, [download])

  const getCsvData = () => {
    if (reactTable.current != null) return reactTable.current.getResolvedState().sortedData

    return new Object[0]()
  }

  const getHeaders = () => {
    const result = newColumns
      .filter(item => !item.skipCsv)
      .map(item => ({ label: item.csvHeader, key: item.accessor }))

    return result
  }

  return (
    <div className="esr-table">
      {newDownload && (
        <CSVDownload
          separator={';'}
          data={getCsvData}
          headers={getHeaders}
          uFEFF={false}
          fileName={type}
        />
      )}
      <ReactTable
        ref={reactTable}
        key={JSON.stringify(newColumns)}
        data={rows}
        columns={newColumns}
        defaultPageSize={1000}
        minRows={minRows}
        filterable={filterable}
        defaultFilterMethod={filterCaseInsensitive}
        showPagination={false}
        className="-highlight "
        sx={style.sxTable(colorMode)}
        getTdProps={() => ({
          style: {
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderRightColor: theme.colors.background,
          },
        })}
        getTableProps={() => ({ style: visible })}
        getTheadProps={() => ({
          style: style.thead,
        })}
        getTheadThProps={() => {
          return { style: { padding: '0px 0px', borderRight: '0px' } }
        }}
        getTrGroupProps={() => {
          return { style: { borderColor: theme.colors.background } }
        }}
        getTheadFilterProps={() => ({
          style: {
            position: 'sticky',
            top: theme.positions.tableFilter[colorMode],
          },
        })}
        getTheadFilterThProps={() => ({
          style: {
            borderRight: `1px solid ${theme.colors.background}`,
          },
        })}
        getTheadFilterTrProps={() => ({})}
        getTrProps={() => ({ style: { height: 40 } })}
        getResizerProps={() => ({ style: { cursor: 'ew-resize' } })}
        onFilteredChange={filters => setFilters({ tabIndex, filters })}
        {...props}
      >
        {(_, makeTable) => <div style={visible}> {makeTable()}</div>}
      </ReactTable>
    </div>
  )
}

const mapStateToProps = (
  { workspace: { currentWorkspace, workspaces }, csv: { csv } },
  ownProps
) => ({
  download: csv[currentWorkspace]?.tabs[ownProps.tabIndex]?.download,
  type: workspaces[currentWorkspace]?.tabs[ownProps.tabIndex]?.type,
  currentWorkspace,
})
const mapDispatchToProps = {
  setFilters,
  setDownloadCsv,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Table)
