const defaultState = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
  dataIds: [],
  groupBy: {},
}

export default (entity = 'ENTITY', keys = undefined, groupBy = undefined) => (
  state = defaultState,
  { type, payload } = {}
) => {
  switch (type) {
    case `FETCH_${entity}_PENDING`:
      return { ...state, fetching: true, fetched: false, error: null }
    case `FETCH_${entity}_REJECTED`:
      return { ...state, fetching: false, fetched: false, error: payload }
    case `FETCH_${entity}_FULFILLED`:
      const data = payload.reduce((acc, entry) => {
        return { ...acc, [getKey(keys, entry)]: { ...entry } }
      }, {})

      const dataIds = Object.keys(data)

      let groups = {}
      if (groupBy) {
        groups = payload.reduce(
          (acc, entry) => ({ ...acc, [getKey(groupBy, entry)]: { data: [], keys: [...groupBy] } }),
          {}
        )

        payload.forEach((p, i) => {
          const getGroup = entry => getKey(groupBy, entry)
          const g = getGroup(p)
          if (groups[g]) {
            groups[g].data.push(dataIds[i])
          }
        })
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data,
        dataIds,
        groupBy: groups,
      }
    default:
      return state
  }
}

function getKey(keys, entry) {
  let key = ''
  const lastItem = keys.lenght - 1
  keys.forEach(
    (k, index) => (key = key + (index === 0 || index === lastItem ? '' : ':') + entry[k])
  )
  return key
}
