import { view, set, over, compose, identity, lift, lensPath, omit, mergeLeft } from 'ramda'

import { cLens } from '../common/utilities'

export const defaultState = { tick: {}, trade: {}, depth: {} }

export default (state = defaultState, { type, payload } = {}) => {
  const tickLens = lensPath(['tick'])
  const tradeLens = lensPath(['trade'])

  switch (type) {
    case 'UPDATE_MARKET_TICK_DATA':
      const tickSymbolLens = cLens(tickLens, payload.symbol)
      const update = over(tickSymbolLens, mergeLeft(payload))
      const viewAskPrice = view(cLens(tickSymbolLens, 'askPrice'))
      const viewBidPrice = view(cLens(tickSymbolLens, 'bidPrice'))
      const setLastAskPrice = set(cLens(tickSymbolLens, 'lastAskPrice'))
      const setLastBidPrice = set(cLens(tickSymbolLens, 'lastBidPrice'))
      const setAsk = lift(setLastAskPrice)(viewAskPrice, identity)
      const setBid = lift(setLastBidPrice)(viewBidPrice, identity)
      return compose(update, setAsk, setBid)(state)
    case 'REMOVE_MARKET_TICK_DATA':
      return over(tickLens, omit([payload]), state)
    case 'UPDATE_MARKET_TRADE_DATA':
      return over(cLens(tradeLens, payload.symbol), mergeLeft(payload), state)
    case 'REMOVE_MARKET_TRADE_DATA':
      return over(tradeLens, omit([payload]), state)
    case 'UPDATE_MARKET_BOOK_DATA':
      if (payload.book) {
        const book = payload.book.reduce(
          (acc, b) => ({
            ...acc,
            [b.depth]: {
              ...b,
              lastAskPrice: state?.depth[payload.symbol]?.[b.depth]?.askPrice || '',
              lastBidPrice: state?.depth[payload.symbol]?.[b.depth]?.bidPrice || '',
            },
          }),
          {}
        )
        return {
          ...state,
          depth: {
            ...state.depth,
            [payload.symbol]: { ...book },
          },
        }
      } else {
        return state
      }
    case 'REMOVE_MARKET_BOOK_DATA':
      const updateData = { ...state.depth }
      delete updateData[payload]
      return {
        ...state,
        depth: {
          ...updateData,
        },
      }
    case 'MARKET_SOCKET_CONNECTED':
      return { ...state, connected: true }
    default:
      return state
  }
}
