/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useContext } from 'react'

import { Field, Label, Text } from './style'
import Grid from '@material-ui/core/Grid'
import { I18nContext } from '../../containers/i18n'

const OrderData = ({ nameData }) => {
  const { t } = useContext(I18nContext)

  const FormatField = ({ nameData, formatter }) => (
    <>
      {nameData?.name && (
        <Field>
          <Label>{nameData.name}</Label>
          <Text>
            {formatter ? formatter(nameData.data) : t(nameData.data) || nameData.data || ''}
          </Text>
        </Field>
      )}
    </>
  )

  return (
    <Grid container spacing={8}>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.instrument} />
        <FormatField nameData={nameData.side} />
        <FormatField nameData={nameData.masterOrderId} />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.orderType} />
        <FormatField nameData={nameData.expiry} />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <FormatField nameData={nameData.currency} />
        <FormatField nameData={nameData.account} />
        <FormatField nameData={nameData.exchangeId} />
      </Grid>
    </Grid>
  )
}

export default OrderData
