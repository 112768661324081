import keycloak from '../../assets/keycloak.json'
import keycloakPre from '../../assets/keycloak.pre.json'
import keycloakProd from '../../assets/keycloak.prod.json'

const config = () => {
  const keycloakConfig = {
    realm: 'bmefx',
    url: window.env.KEYCLOAK_URL,
    clientId: 'bmefx-frontend',
  }

  return {
    urls: {
      baseUrl: window.env.BASE_URL,
      marketData: window.env.MARKET_DATA,
      orders: window.env.ORDERS,
      rfq: window.env.RFQ,
      positions: window.env.POSITIONS,
    },
    keycloak: keycloakConfig,
    reconnectDelay: 5000,
  }
}

export default config
