import {
  Dialog as MDialog,
  DialogActions as MDialogActions,
  DialogContent as MDialogContent,
  DialogTitle as MDialogTitle,
  Select as MSelect,
} from '@material-ui/core'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

const padds = css`
  padding-top: 15px;
  padding-bottom: 15px;
`

export const Dialog = styled(MDialog)`
  border-radius: none;
`

Dialog.defaultProps = {
  maxWidth: false,
  PaperProps: { square: true },
}

export const DialogTitle = styled(MDialogTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
  padding: 15px 0;
  font-family: ${props => props.theme.fonts.tabs[props.colormode]};
  ${({ settings }) => settings && padds}
`

export const DialogContent = styled(MDialogContent)`
  padding: 0px;
`

export const DialogBanner = styled.div`
  height: 70px;
  display: flex;
  padding: 0px 50px;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  background-color: ${({ buy }) =>
    buy ? props => props.theme.colors.dialog.buy : props => props.theme.colors.dialog.sell};
`

export const DialogSubtitle = styled.div`
  margin: 15px 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: 400;
`

export const DialogActions = styled(MDialogActions)`
  display: flex;
  justify-content: center;
  padding: 0 10px 10px 10px;
  height: 60px;
`

export const Select = styled(MSelect)`
  background-color: ${props => props.theme.colors.primary};
  width: 35%;
  color: ${props => props.theme.colors.text};
  padding: 3px;
  padding-left: 15px;
  margin-top: 15px;
  &::after {
    border-bottom: none;
  }
`

export const DialogContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

export const DialogRowElement = styled.div(props => ({
  display: 'flex',
  flex: '1',
  marginTop: '1px',
  justifyContent: 'right',
  padding: '0 10px',
  width: props.width,
}))

export const Label = styled.label`
  min-width: 80px;
  text-align: center;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.dialog.label};
  font-size: 1rem;
  padding: 5px 15px;
`
