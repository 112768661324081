/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'
import { Search, Edit, Cancel } from '@material-ui/icons'

import { PanelTable } from '../Table'
import * as style from './style'
import { columnsHead, defaultSorted } from './columnsHead'
import 'react-table/react-table.css'
import ModalOrderView from '../ModalOrderView'
import ModalOrderEdit from '../ModalOrderEdit'

import {
  getOrderList,
  showOrderDialog,
  closeOrderDialog,
  cancelOrderWithConfirm,
  replaceOrder,
} from '../../actions/orders'
import { subscribeTickSymbol } from '../../actions/marketData'
import { fmtDateLong, fmtDateLongFromyyyMMdd } from '../../common/utilities'
import { I18nContext } from '../../containers/i18n'

/**
 * Check if provided order is an application
 */
const isApplicationOrder = order => order.orderType === 'APPLICATION'

/*
 * Statuses for which editing will not be enabled
 */
const orderDisabledEditStatuses = [
  'FILLED',
  'CANCELLED',
  'REJECTED',
  'PENDING_NEW',
  'PENDING_REPLACE',
  'PENDING_CANCEL',
]

const defaultExcludeColumns = ['clientOrderId', 'execStatus', 'exchangeId']

const OrderList = ({
  cancelOrderWithConfirm,
  closeOrderDialog,
  getOrderList,
  keycloak,
  modal,
  orderList,
  replaceOrder,
  showOrderDialog,
  tabIndex,
  subscribeTickSymbol,
  accounts,
}) => {
  const { t } = useContext(I18nContext)

  const cancelOrder = data => {
    const accountFromOperationAccount =
      accounts[order.operationAccount] &&
      `${accounts[order.operationAccount].name} (${accounts[order.operationAccount].fixAccount})`
    const payload = {
      origClOrdId: data.clientOrderId,
      symbol: data.symbol,
      exchangeId: data.exchangeId,
      title: accountFromOperationAccount
        ? `${t('account')} ${accountFromOperationAccount}`
        : `${t('account')} ${order.operationAccount}`,
      order: true,
      side: data.side,
      operationAccount: data.operationAccount,
      selectedAccount: accounts[data.operationAccount] || order.operationAccount,
    }
    cancelOrderWithConfirm(payload, keycloak.token)
  }

  useEffect(() => {
    getOrderList(keycloak.token)
  }, [])

  //Actions cell format

  const handleCancel = order => {
    const accountFromOperationAccount =
      accounts[order.operationAccount] &&
      `${accounts[order.operationAccount].name} (${accounts[order.operationAccount].fixAccount})`
    const payload = {
      origClOrdId: order.clientOrderId,
      symbol: order.symbol,
      exchangeId: order.exchangeId,
      title: accountFromOperationAccount
        ? `${t('account')} ${accountFromOperationAccount}`
        : `${t('account')} ${order.operationAccount}`,
      orderInfo: order,
      order: true,
      side: order.side,
      operationAccount: order.operationAccount,
      selectedAccount: accounts[order.operationAccount] || order.operationAccount,
    }
    cancelOrderWithConfirm(payload, keycloak.token)
  }

  const handleModal = (order, viewMode, status) =>
    showOrderDialog({
      data: order,
      title: t('orderDetails'),
      viewMode,
      status,
    })

  const handleEditModal = (order, viewMode, status) =>
    showOrderDialog({
      data: order,
      viewMode,
      status,
    })

  /*
   * Returns if an order is editable
   */
  const isOrderEditable = order =>
    order && !orderDisabledEditStatuses.includes(order.orderStatus) && !isApplicationOrder(order)

  /* The actions cell formatted */
  const ActionsCellFormat = ({ order }) => {
    const disabled = !isOrderEditable(order)
    return order
      ? [
          <Search
            sx={{ cursor: 'pointer' }}
            key={1}
            onClick={() =>
              handleModal(
                { ...order, history: [order, order, order, order, order] },
                true,
                order.orderStatus
              )
            }
          />,
          <Edit
            key={2}
            sx={{ ...(disabled ? style.disabled : { cursor: 'pointer' }) }}
            onClick={() => {
              if (!disabled) {
                subscribeTickSymbol({ symbol: order.symbol, exchangeId: order.exchangeId })
                handleEditModal(order, false, order.orderStatus)
              }
            }}
          />,
          <Cancel
            key={3}
            sx={{ ...(disabled ? style.disabled : { cursor: 'pointer' }) }}
            onClick={() => !disabled && handleCancel(order)}
          />,
        ]
      : []
  }

  const memoizedTableData = useMemo(() => {
    return isEmpty(orderList)
      ? []
      : orderList.map(o => ({
          ...o,
          orderId: o.orderId && o.orderId !== 'NONE' ? o.orderId : '',
          actions: <ActionsCellFormat order={o} />,
          formatDate: o.date && fmtDateLong(o.date),
          formatUpdateDate: o.updateDate && fmtDateLong(o.updateDate),
          formatExpirationDate: o.expirationDate && fmtDateLongFromyyyMMdd(o.expirationDate),
          formatQuantity: +o.quantity || '',
          formatPrice: +o.price || '',
          formatAveragePrice: +o.averagePrice || '',
          formatPendingQuantity: !isNaN(o.pendingQuantity) ? +o.pendingQuantity : '',
          formatAccount: accounts[o.operationAccount]?.name || '',
        }))
  }, [orderList, accounts])

  return (
    <PanelTable
      rows={memoizedTableData}
      getColumns={() => columnsHead(t)}
      tabIndex={tabIndex}
      defaultSorted={defaultSorted}
    >
      {modal.open ? (
        <>
          <ModalOrderEdit
            {...modal}
            sx={style.modal}
            onClose={closeOrderDialog}
            onSave={replaceOrder}
            open={modal.open && !modal.viewMode}
            token={keycloak.token}
          />
          <ModalOrderView
            {...modal}
            sx={style.modal}
            open={modal.open && modal.viewMode}
            onClose={closeOrderDialog}
            onCancel={cancelOrder}
            onModify={showOrderDialog}
            token={keycloak.token}
          />
        </>
      ) : null}
    </PanelTable>
  )
}

const mapStateToProps = ({ order: { orderList, modal }, accounts: { data: accounts } }) => ({
  orderList,
  modal,
  accounts,
})

const mapDispatchToProps = {
  getOrderList,
  showOrderDialog,
  closeOrderDialog,
  cancelOrderWithConfirm,
  replaceOrder,
  subscribeTickSymbol,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
